import {stringToInt} from './type'
import {getEmailRegex, getPhoneRegex} from './regex'
import {
    addScript,
    isWebview,
} from './element'
import {
    getServerMediaPath, 
    getDateByServer,
    getWorksheetGradeByWorksheet,
    makeProblemPreviewToPdf,
    getSimpleGrade,
    getWorkbookGradeByWorkbook,
    getSchoolTypeByGradeList,
    getSchoolYearByGradeList,
    getSubjectByGradeList,
    numberFormat,
    isV2Url,
    getFirstTeacherRouteName,
} from './parse'
import {
    onClickWorksheetHomework,
    getPreviewUrl,
    previewLink,
    back,
    downloadUrl,
    debugLog,
} from './event'
import {
    isMobile,
    getPdfSize,
} from './calc'
import {
    PdfType,
    PdfViewType,
    myPageTeacherRouterNames,
    DownloadType,
    StudentProgressType,
    StudentHomeworkProgressType,
    getV2TeacherRouterItems,
    getV2StudentRouterItems,
} from './enums'
import {
    getOrCreateTooltip,
    dailyStudentHoursChartTooltip,
} from './chart'
import {
    getWeekNumberOfMonth,
    getWeekFirstDateByDt,
    getWeekLastDateByDt,
    getWeekDateRangeByDt,
    parseYmdHms,
    parseYmd,
    parseMonthWeek,
    getFirstDateByYear,
    isIncludeBetweenDate,
} from './date'
import {
    writeClipboardText,
} from './utils'

export default {
    stringToInt,
    getEmailRegex,
    getServerMediaPath,
    getDateByServer,
    addScript,
    isWebview,
    getWorksheetGradeByWorksheet,
    makeProblemPreviewToPdf,
    getSimpleGrade,
    getWorkbookGradeByWorkbook,
    getSchoolTypeByGradeList,
    getSchoolYearByGradeList,
    getSubjectByGradeList,
    onClickWorksheetHomework,
    getPreviewUrl,
    previewLink,
    back,
    downloadUrl,
    numberFormat,
    isV2Url,
    getFirstTeacherRouteName,
    getWeekNumberOfMonth,
    getWeekFirstDateByDt,
    getWeekLastDateByDt,
    getWeekDateRangeByDt,
    parseYmdHms,
    parseYmd,
    parseMonthWeek,
    getFirstDateByYear,
    isIncludeBetweenDate,
    getPhoneRegex,
    isMobile,
    getPdfSize,
    PdfType,
    PdfViewType,
    myPageTeacherRouterNames,
    DownloadType,
    StudentProgressType,
    StudentHomeworkProgressType,
    debugLog,
    getOrCreateTooltip,
    dailyStudentHoursChartTooltip,
    getV2TeacherRouterItems,
    getV2StudentRouterItems,
    writeClipboardText,
}
