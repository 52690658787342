<template>
  <div id="wrap">
    <div id="container">
      <section class="sec_top">
        <div class="top_slider_wrap">
          <div class="top_slider swiper-container">
            <ul class="swiper-wrapper">
              <li class="swiper-slide">
                <div class="wrapper">
                  <div class="copy_wrap">
                    <em>국내유일 AI 변형
                      <br/>No.1 과학 문제은행 에스덕</em>
                    <p><b>학교/학원 과학 선생님만을 위한 <i>대표 과학문제은행, Sduck.</i></b>
                      에스덕은 자체 AI로 시중문제를 변형문제로 <i>제공하는 서비스 입니다.
                        <br/>이 순간에도 에스덕AI가 </i>문제를 추가하고 있습니다.</p>
                    <div class="btn_free"
                         @click="onClickFreeExp"
                    >{{ freeExpText }}</div>
                  </div>
                  <div class="img_wrap slide01">
                    <div class="slider_layer01"></div>
                    <div class="slider_layer02"></div>
                    <div class="slider_layer02_1"></div>
                    <div class="slider_layer03"></div>
                    <div class="slider_layer04"></div>
                    <div class="slider_layer05"></div>
                    <div class="slider_layer06"></div>
                  </div>
                </div>
              </li>
              <li class="swiper-slide">
                <div class="wrapper">
                  <div class="copy_wrap">
                    <em>Sduck,
                      <br/>과학 선생님의 파트너!</em>
                    <p>이제는 구하기 어려웠던 과학 문제를 여기서 찾으세요.
                      <br/>과학 교육에 필요한 모든 문제를 한 곳에서,
                      <br/>선생님들을 위해 준비했습니다.</p>
                    <div class="btn_free"
                         @click="onClickFreeExp"
                    >{{ freeExpText }}</div>
                  </div>
                  <div class="img_wrap slide02">
                    <div class="slider_layer01"></div>
                    <div class="slider_layer02"></div>
                    <div class="slider_layer03"></div>
                    <div class="slider_layer04"></div>
                    <div class="slider_layer05"></div>
                  </div>
                </div>
              </li>
              <li class="swiper-slide">
                <div class="wrapper">
                  <div class="copy_wrap">
                    <em>No.1 과학 문제은행
                      <br/>Sduck, 할인안내</em>
                    <p><b>에스덕 Basic / 에스덕 3 Month</b>
                      기간 : 2024년 03월 가입까지ㅣ혜택 : 50만 등록비 무료
                      <br/>학교용 : 6 Month / 1 year은 추가할인!</p>
                    <div class="btn_free"
                         @click="onClickFreeExp"
                    >{{ freeExpText }}</div>
                  </div>
                  <div class="img_wrap slide03">
                    <div class="slider_layer01"></div>
                    <div class="slider_layer02"></div>
                    <div class="slider_layer03"></div>
                    <div class="slider_layer04"></div>
                    <div class="slider_layer05"></div>
                  </div>
                </div>
              </li>
              <li class="swiper-slide">
                <div class="wrapper">
                  <div class="copy_wrap">
                    <em>Sduck,
                      <br/>과학 AI 문제은행의 혁신!</em>
                    <p>학원, 학교 선생님들에게
                      <br/>업무에 편리하고 유용한 온, 오프라인 출제, 자동채점,
                      <br/>최적화된 1:1 맞춤형 과학 문제를 무제한 제공합니다.</p>
                    <div class="btn_free"
                         @click="onClickFreeExp"
                    >{{ freeExpText }}</div>
                  </div>
                  <div class="img_wrap slide04">
                    <div class="slider_layer01"></div>
                    <div class="slider_layer02"></div>
                    <div class="slider_layer03"></div>
                    <div class="slider_layer04"></div>
                    <div class="slider_layer05"></div>
                  </div>
                </div>
              </li>
              <li class="swiper-slide">
                <div class="wrapper">
                  <div class="copy_wrap">
                    <em>스마트한 과학 선생님들의 선택
                      <br/>Sduck!</em>
                    <p>발빠른 학원 선생님과 디지털 선도학교 선생님은 이미 사용중입니다.
                      <br/>학교에선 양질의 자료를, 학원에선 매출상승을 경험하세요.</p>
                    <div class="btn_free"
                         @click="onClickFreeExp"
                    >{{ freeExpText }}</div>
                  </div>
                  <div class="img_wrap slide05">
                    <div class="slider_layer01"></div>
                    <div class="slider_layer02"></div>
                    <div class="slider_layer03"></div>
                    <div class="slider_layer04"></div>
                    <div class="slider_layer05"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="nav_wrap">
            <div class="wrapper">
              <div class="dots_wrap">
                <button type="button" class="nav_prev"><span class="blind">이전</span>01</button>
                <div class="dots"></div>
                <button type="button" class="nav_next"><span class="blind">다음</span>05</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="sec_service">
        <div class="sec_tit centered">
          <h2>과학 선생님 업무에 필요한 <i>모든 것을 담았습니다.</i></h2>
          <p>선생님의 업무의 편리함 효율성을 위해 만들었어요. <i>에스덕은 업무에 필요한 모든 서비스를 지원합니다.</i></p>
        </div>
        <div class="service_slider_wrap">
          <div class="service_slider">
            <ul>
              <li>
                <em>AI 변형 <span>문제 연동</span></em>
                <p>AI를 통한 유사문제 생성</p>
                <i class="ico_service01"></i>
                <div class="item-link" @click="toastComingSoon">자세히보기</div>
              </li>
              <li>
                <em>시중교재 연동문항
                  <br/>무한제공</em>
                <p>시중교재 - 번호 연동 쌍둥이 문제</p>
                <i class="ico_service02"></i>
                <div class="item-link" @click="toastComingSoon">자세히보기</div>
              </li>
              <li>
                <em>대,중,소 유형별 <br/><span>문제분류</span></em>
                <p>대단원~소단원,
                  <br/>유형까지 맞춤형 문제제공</p>
                <i class="ico_service03"></i>
                <div class="item-link" @click="toastComingSoon">자세히보기</div>
              </li>
              <li>
                <em>온라인 <span>출제</span></em>
                <p>25~250문항, 난이도, 유형
                  <br/>자유롭게 학습지 제작가능</p>
                <i class="ico_service04"></i>
                <div class="item-link" @click="toastComingSoon">자세히보기</div>
              </li>
              <li>
                <em>오프라인 <span>출제</span></em>
                <p>PDF다운 및 출력
                  <br/>(학교는 요청시 hwp제공)</p>
                <i class="ico_service05"></i>
                <div class="item-link" @click="toastComingSoon">자세히보기</div>
              </li>
              <li>
                <em>학업분석 <span>보고서 작성</span></em>
                <p>학부모 앱을 통한 빠른 학업 분석가능</p>
                <i class="ico_service06"></i>
                <div class="item-link" @click="toastComingSoon">자세히보기</div>
              </li>
              <li>
                <em>자동채점</em>
                <p>온라인 출제시 자동채점</p>
                <i class="ico_service07"></i>
                <div class="item-link" @click="toastComingSoon">자세히보기</div>
              </li>
              <li>
                <em>학습지 제작</em>
                <p>25~250문항, 난이도, 유형
                  <br/>자유롭게 학습지 제작가능</p>
                <i class="ico_service08"></i>
                <div class="item-link" @click="toastComingSoon">자세히보기</div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="sec_prlx">
        <div class="prlx_cont">
          <div class="prlx_slider swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide slide_item">
                <div class="slide_col">
                  <div class="col_img slide01">
                    <div class="img_wrap">
                      <div class="slider_layer01"></div>
                      <div class="slider_layer02"></div>
                      <div class="slider_layer03"></div>
                      <div class="slider_layer04"></div>
                      <div class="slider_layer05"></div>
                      <div class="slider_layer06"></div>
                      <div class="slider_layer07"></div>
                    </div>
                  </div>
                  <div class="col_copy">
                    <div class="inner">
                      <em><span>맞춤형</span> 문제은행교재,
                        <br/>학습지 제작</em>
                      <p>다른 실력인데 하나의 학습지로만 수업하나요?
                        <br/>중간고사, 수준별특강 등 반의 성격, 시험지를 밤새가면서 만드셨나요?
                      </p>
                      <p>에스덕은 중단원, 소단원, 유형, 난이도로 단원을 쪼개서 필요한 범위만,
                        <br/>유형은 실험형, 수능형, 단답형까지 세분화하여 학생 1명을 위한
                        <br/>단 하나의 학습지가 생성됩니다.
                      </p>
                      <p>클릭 몇번으로 학습지는 최대 100문제까지, 교재는 최대 500문제까지,
                        <br/>선생님이 원하는 난이도로, 시험지 제작의 퀄리티를 높여주세요!</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide slide_item">
                <div class="slide_col">
                  <div class="col_img slide02">
                    <div class="img_wrap">
                      <div class="slider_layer01"></div>
                      <div class="slider_layer02"></div>
                      <div class="slider_layer03"></div>
                      <div class="slider_layer04"></div>
                      <div class="slider_layer05"></div>
                      <div class="slider_layer06"></div>
                      <div class="slider_layer07"></div>
                    </div>
                  </div>
                  <div class="col_copy">
                    <div class="inner">
                      <em>AI로 높은 시중교재 매칭률로
                        <br/><span>양질의 콘텐츠</span> 제공</em>
                      <p>학교에 학생이 이해했는지 확인이 어려우셨나요?
                        <br/>학원에서 학교 시험범위가 달라 번거로우셨나요?
                      </p>
                      <p>에스덕 AI가 시중교재의 유사문제를 제공하여  내신과 수능대비를
                        <br/>효율적으로 할 수 있습니다.
                      </p>
                      <p>짧은 시간에 고효율의 효과로 성적 상승과 탄탄한 수업의 기반이 됩니다.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide slide_item">
                <div class="slide_col">
                  <div class="col_img slide03">
                    <div class="img_wrap">
                      <div class="slider_layer01"></div>
                      <div class="slider_layer02"></div>
                      <div class="slider_layer03"></div>
                      <div class="slider_layer04"></div>
                      <div class="slider_layer05"></div>
                      <div class="slider_layer06"></div>
                      <div class="slider_layer07"></div>
                      <div class="slider_layer08"></div>
                      <div class="slider_layer09"></div>
                      <div class="slider_layer10"></div>
                      <div class="slider_layer11"></div>
                    </div>
                  </div>
                  <div class="col_copy">
                    <div class="inner">
                      <em>아날로그는 이제 그만
                        <br/><span>온라인 출제 & 자동채점</span></em>
                      <p>다른 실력인데 하나의 학습지로만 수업하나요?
                        <br/>중간고사, 수준별특강 등 반의 성격, 시험지를 밤새가면서 만드셨나요?
                      </p>
                      <p>에스덕은 중단원, 소단원, 유형, 난이도로 단원을 쪼개서 필요한 범위만,
                        <br/>유형은 실험형, 수능형, 단답형까지 세분화하여 학생 1명을 위한
                        <br/>단 하나의 학습지가 생성됩니다.
                      </p>
                      <p>클릭 몇번으로 학습지는 최대 100문제까지, 교재는 최대 500문제까지,
                        <br/>선생님이 원하는 난이도로, 시험지 제작의 퀄리티를 높여주세요!</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide slide_item">
                <div class="slide_col">
                  <div class="col_img slide04">
                    <div class="img_wrap">
                      <div class="slider_layer01"></div>
                      <div class="slider_layer02"></div>
                      <div class="slider_layer03"></div>
                      <div class="slider_layer04"></div>
                      <div class="slider_layer05"></div>
                      <div class="slider_layer06"></div>
                      <div class="slider_layer07"></div>
                    </div>
                  </div>
                  <div class="col_copy">
                    <div class="inner">
                      <em>Sduck! 하나로
                        <br/><span>1:1 맞춤형 학습지</span> 제작</em>
                      <p>이리 저리 학교마다 다른 시험범위에 찾아다니지 마세요.
                        <br/>대단원, 중단원, 소단원 ,유형까지 세분화, 한 학생에게 필요한
                        <br/>범위의 문제출제 서비스를 제공합니다.
                        <br/>과학 수업의 편리함을 느끼세요</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide slide_item">
                <div class="slide_col">
                  <div class="col_img slide05">
                    <div class="img_wrap">
                      <div class="slider_layer01"></div>
                      <div class="slider_layer02"></div>
                      <div class="slider_layer03"></div>
                      <div class="slider_layer04"></div>
                      <div class="slider_layer05"></div>
                      <div class="slider_layer06"></div>
                      <div class="slider_layer07"></div>
                    </div>
                  </div>
                  <div class="col_copy">
                    <div class="inner">
                      <em>더 전문성 있게,
                        <br/>학업 <span>분석 보고서</span></em>
                      <p>학생의 약한 부분을 한눈에! 상담 및 홍보에 전문성 up!
                      </p>
                      <p>학업 동기부여는, 학생의 수준을 눈으로 보여주는 것이 필요합니다.
                        <br/>선택과 집중을 해야 할 부분이 무엇인지, 데이터를 분석하여 등수와
                        <br/>상황을 알 수 있습니다.
                      </p>
                      <p>간편 / 월 보고서로 신규생, 학부모까지 퀄리티 높고 전문적인
                        <br/>상담을 제공해주세요.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide slide_item">
                <div class="slide_col">
                  <div class="col_img slide06">
                    <div class="img_wrap">
                      <div class="slider_layer01"></div>
                      <div class="slider_layer02"></div>
                      <div class="slider_layer03"></div>
                      <div class="slider_layer04"></div>
                      <div class="slider_layer05"></div>
                      <div class="slider_layer06"></div>
                      <div class="slider_layer07"></div>
                    </div>
                  </div>
                  <div class="col_copy">
                    <div class="inner">
                      <em>에스덕 AI 는 쉬지 않습니다.
                        <br/>점점 <span>확장되는 과목 수</span></em>
                      <p>중등 과학부터 고등 과탐까지!
                      </p>
                      <p>학교, 학원 분야에 다양한 범위에서 사용 가능합니다.
                        <br/>초등과학, 영재원, 특수학교, 논술, 공무원, 자격증 등 에스덕 AI가 콘텐츠를
                        <br/>폭넓게 추가 할 예정입니다.
                      </p>
                      <p>물론 가입 후 약정기간없이 사용하실 수 있습니다.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="nav_wrap">
            <div class="dots_wrap">
              <button type="button" class="nav_prev"><span class="blind">이전</span>01</button>
              <div class="dots"></div>
              <button type="button" class="nav_next"><span class="blind">다음</span>06</button>
            </div>
          </div>
        </div>
        <div class="prlx_track">
          <div class="track slider01" data-track="1"></div>
          <div class="track slider02" data-track="1"></div>
          <div class="track slider03" data-track="1"></div>
          <div class="track slider04" data-track="1"></div>
          <div class="track slider05" data-track="1"></div>
          <div class="track slider06" data-track="1"></div>
        </div>
      </section>
      <section class="sec_counter">
        <div class="wrapper">
          <div class="sec_tit large">
            <h2>이미 많은 선생님들과 학생들이,
              <br/><b>함께</b>하고 있어요.</h2>
          </div>
          <div class="counter_wrap">
            <ul>
              <li class="count_teacher">
                <em><i class="ico_teacher"></i>선생님 회원</em>
                <p><b class="counter" data-count="134">0</b>명</p>
              </li>
              <li class="count_student">
                <em><i class="ico_student"></i>학생 회원</em>
                <p><b class="counter" data-count="521">0</b>명</p>
              </li>
              <li class="count_book">
                <em><i class="ico_book"></i>생성된 학습지 수</em>
                <p><b class="counter" data-count="431">0</b>부</p>
              </li>
              <li class="count_exam">
                <em><i class="ico_exam"></i>문제 생성 수</em>
                <p><b class="counter" data-count="11453">0</b>건</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="sec_review">
        <div class="wrapper">
          <div class="sec_tit">
            <h2>함께하고 있는 선생님들이 <i>증명하고 있어요.</i></h2>
            <div class="nav_wrap">
              <div class="dots_wrap">
                <button type="button" class="nav_prev"><span class="blind">이전</span>01</button>
                <div class="dots"></div>
                <button type="button" class="nav_next"><span class="blind">다음</span>04</button>
              </div>
            </div>
          </div>
          <div class="review_slider swiper-container">
            <ul class="swiper-wrapper">
              <li class="swiper-slide"
                  v-for="(review, index) in this.reviews"
                  :key="index"
              >
                <div class="thumb">
                  <div :style="[{
                    'background-image': `url(${utils.getServerMediaPath(review.thumbnail)})`,
                  }]"></div>
                </div>
                <div class="info">
                  <p>{{ review.title }}</p>
                  <span>{{ review.contents }}</span>
                  <a class="item-link"
                     :href="review.link_url"
                     target="_blank"
                  >리뷰보기</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="sec_banner">
        <div class="symbol_wrap">
          <div class="symbol01"></div>
          <div class="symbol02"></div>
          <div class="symbol03"></div>
          <div class="symbol04"></div>
          <div class="symbol05"></div>
          <div class="symbol06"></div>
        </div>
        <div class="copy_wrap">
          <em>무료 에스덕 사용해보기</em>
          <p>지금 바로 시작하세요!</p>
          <div class="btn_free"
               @click="onClickFreeExp"
          >{{ freeExpText }}</div>
        </div>
      </section>
    </div>
    <template v-if="!this.isPaidTeacher">
      <div class="float_banner">
        <div @click="openApplyFreeExp">
          <div class="banner"></div>
        </div>
      </div>
    </template>
  </div>

  <ApplyFreeExpPopup
      :isVisible="this.isOpenApplyFreeExpPopup"
      :onClose="() => { this.isOpenApplyFreeExpPopup = false; }"
  />

  <template v-for="(popup, index) in popups" :key="index">
    <HomePopup
        :isVisible="popup.is_visible"
        :popup="popup"
        :onClose="() => { popup.is_visible = false }"
    />
  </template>
</template>

<script>
import AcademyService from "@/services/academy.service";
import utils from "@/utils";
import HomePopup from "@/components/HomePopup.vue";
import CommonService from "@/services/common.service";
import ApplyFreeExpPopup from "@/components/ApplyFreeExpPopup.vue";
import {mapGetters} from "vuex";

export default {
  components: {ApplyFreeExpPopup, HomePopup},
  data() {
    return {
      reviews: [],
      utils,
      popups: [],
      isOpenApplyFreeExpPopup: false,
    };
  },
  mounted() {
    this.$loadScript('/js/swiper.8.4.7.js').then(() => {
      this.$loadScript('/js/home-common.js');
    });
    this.initReviews();
    this.initPopups();
  },
  methods: {
    openApplyFreeExp() {
      this.isOpenApplyFreeExpPopup = true;
    },
    onClickFreeExp() {
      if (this.isPaidTeacher) {
        this.redirectTeacherAdminPage();
      } else if (this.isAcademyStudent) {
        this.redirectStudentPage();
      } else {
        this.openApplyFreeExp();
      }
    },
    async initPopups() {
      this.popups = (await CommonService.getOpenPopups()).map(popup => {
        popup.is_visible = true;
        return popup;
      });
    },
    async initReviews() {
      this.reviews = await AcademyService.getReviewList();
      await this.$loadScript('/js/home-review-slider.js');
    },
    toastComingSoon() {
      this.$toast.warning('해당 기능은 준비중입니다');
    },
    redirectTeacherAdminPage() {
      const routeName = utils.getFirstTeacherRouteName(this.isSelectV2);
      this.$router.push({ name: routeName });
    },
    redirectStudentPage() {
      const routeName = utils.getV2StudentRouterItems(this.getUser)[0].subItems[0].routerName;
      this.$router.push({ name: routeName });
    },
  },
  computed: {
    freeExpText() {
      if (this.isPaidTeacher) {
        return '에스덕 접속하기';
      } else if (this.isAcademyStudent) {
        return '에스덕 접속하기';
      }
      return '무료 체험하기';
    },
    ...mapGetters('auth', ['getUser', 'isPaidTeacher', 'isAcademyStudent']),
    ...mapGetters('selectV2', ['isSelectV2']),
  },
}
</script>
