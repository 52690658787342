import {StudentWorkResultProblemVo} from "@/models/student_work_result_problem_vo";

export class StudentWorkResultVo {
    /** @type {number} */
    id;
    /** @type {StudentWorkResultProblemVo[]} */
    studentWorkProblems;

    constructor(
        id,
        studentWorkProblems,
    ) {
        this.id = id;
        this.studentWorkProblems = studentWorkProblems;
    }

    static fromJson = (json) => new StudentWorkResultVo(
        json.id,
        (json.student_work_problem ?? []).map(e => StudentWorkResultProblemVo.fromJson(e)),
    );
}
