<template>
  <div class="student-wrong-note-wrap">
    <V2Wrapper>
      <div class="swnw-inner">
        <div class="v2-base-container">
          <div class="wi-search-wrap">
            <div class="sw-left-wrap">
              <input ref="searchDatePicker"
                     @change="(event) => this.onSearchDatePickerChangeEvent(event)"
              >
              <p>기간별 오답노트 기록</p>
              <span @click="() => this.$refs.searchDatePicker.click()">{{ this.searchRangeDtStr }}</span>
              <div class="hdw-cal-btn"
                   @click="() => this.$refs.searchDatePicker.click()"
              >
                <img src="@/assets/images/calendar.png" alt="">
              </div>
            </div>
            <div class="sw-right-wrap">
              <label>
                <input
                    v-model="this.searchKeyword"
                    type="text"
                    placeholder="오답노트 이름 검색"
                >
                <img src="@/assets/images/search-2.png" alt=""/>
              </label>
              <button @click="() => this.isShowWrongNoteCreatePopup = true">
                <span>오답노트 만들기</span>
              </button>
            </div>
          </div>
          <div class="wi-table-list-wrap">
            <div class="tl-line">
              <div class="tl-item"></div>
              <div class="tl-item">
                <p class="li-text">오답노트 제목</p>
              </div>
              <div class="tl-item">
                <p class="li-text">생성일</p>
              </div>
              <div class="tl-item">
                <p class="li-text">문제수</p>
              </div>
              <div class="tl-item"></div>
            </div>
            <template v-if="this.errorMsg !== null">
              <ErrorMsgBox :contents="this.errorMsg"/>
            </template>
            <template v-else-if="this.isLoading">
              <div class="wi-loading">
                <b-spinner
                    style="width: 3rem; height: 3rem;"
                    label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template v-else>
              <template v-for="(studentWorkFolderVo, index) in this.searchedStudentWorkFolderVos" :key="index">
                <div class="tl-line"
                     @click="() => this.$router.push({ name: 'StudentWrongNoteDetail', params: {folderId: studentWorkFolderVo.id} })"
                >
                  <div class="tl-item">
                    <img class="folder-img"
                         src="@/assets/images/folder.png"
                         alt=""
                    />
                  </div>
                  <div class="tl-item">
                    <p class="li-text">{{ studentWorkFolderVo.name }}</p>
                  </div>
                  <div class="tl-item">
                    <p class="li-text">{{ studentWorkFolderVo.getCreateDateStr() }}</p>
                  </div>
                  <div class="tl-item">
                    <img class="doc-img" src="@/assets/images/document-2.png" alt=""/>
                    <p class="li-text">{{ studentWorkFolderVo.problemCnt }}</p>
                  </div>
                  <div class="tl-item">
                    <b-dropdown
                        dropleft
                        class="etc-btn"
                        onclick="event.stopPropagation()"
                        ref="etcDropdown"
                    >
                      <template #button-content>
                        <img src="@/assets/images/mypage-etc.png" alt=""/>
                      </template>
                      <b-dropdown-item
                          @click="() => {this.handleEditName(studentWorkFolderVo);}"
                      >
                        <img src="@/assets/images/text-edit.png" alt=""/>
                        <span>이름 수정</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                          @click="() => {this.handleDelete(studentWorkFolderVo);}"
                      >
                        <img src="@/assets/images/delete.png" alt=""/>
                        <span>삭제</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                          @click="() => {this.handleCopy(studentWorkFolderVo);}"
                      >
                        <img src="@/assets/images/copy.png" alt=""/>
                        <span>복제</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                          @click="() => {this.handleSolveAgain(studentWorkFolderVo);}"
                      >
                        <img src="@/assets/images/loop-2.png" alt=""/>
                        <span>다시 풀기</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </V2Wrapper>
  </div>

  <Teleport to="body">
    <WrongNoteCreatePopup
      :is-visible="this.isShowWrongNoteCreatePopup"
      :on-close="() => this.isShowWrongNoteCreatePopup = false"
      :on-changed="() => this.refreshWrongNoteList()"
    />
    <WrongNoteEditNamePopup
      :is-visible="this.editNameStudentWorkFolderVo !== null"
      :on-close="() => this.editNameStudentWorkFolderVo = null"
      :student-work-folder-vo="this.editNameStudentWorkFolderVo"
      :on-changed="() => this.refreshWrongNoteList()"
    />
    <WrongNoteDeletePopup
      :is-visible="this.deleteStudentWorkFolderVo !== null"
      :on-close="() => this.deleteStudentWorkFolderVo = null"
      :student-work-folder-vo="this.deleteStudentWorkFolderVo"
      :on-changed="() => this.refreshWrongNoteList()"
    />
    <WrongNoteCopyPopup
      :is-visible="this.copyStudentWorkFolderVo !== null"
      :on-close="() => this.copyStudentWorkFolderVo = null"
      :student-work-folder-vo="this.copyStudentWorkFolderVo"
      :on-changed="() => this.refreshWrongNoteList()"
    />
    <WrongNoteSolveAgainPopup
      :is-visible="this.solveAgainStudentWorkFolderVo !== null"
      :on-close="() => this.solveAgainStudentWorkFolderVo = null"
      :student-work-folder-vo="this.solveAgainStudentWorkFolderVo"
      :on-changed="() => this.refreshWrongNoteList()"
    />
    <LoadingDialog
      :is-show="this.isLoadingDialog"
    />
  </Teleport>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import utils from "@/utils";
import {WeekDateRangeItem} from "@/items/week_date_range_item";
import StudentWorkService from "@/services/studentWork.service";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import {StudentWorkFolderVo} from "@/models/student_work_folder_vo";
import YoutubeEmbedPopup from "@/components/YoutubeEmbedPopup.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";
import PopupComponent from "@/components/PopupComponent.vue";
import WrongNoteEditNamePopup from "@/components/WrongNoteEditNamePopup.vue";
import WrongNoteDeletePopup from "@/components/WrongNoteDeletePopup.vue";
import WrongNoteCopyPopup from "@/components/WrongNoteCopyPopup.vue";
import WrongNoteSolveAgainPopup from "@/components/WrongNoteSolveAgainPopup.vue";
import WrongNoteCreatePopup from "@/components/WrongNoteCreatePopup.vue";

export default {
  name: 'StudentWrongNote',
  components: {
    WrongNoteCreatePopup,
    WrongNoteSolveAgainPopup,
    WrongNoteCopyPopup,
    WrongNoteDeletePopup,
    WrongNoteEditNamePopup, PopupComponent, LoadingDialog, YoutubeEmbedPopup, ErrorMsgBox, V2Wrapper},
  data() {
    const nowDt = new Date(utils.parseYmd(new Date()));
    const minDt = new Date(nowDt);
    minDt.setFullYear(minDt.getFullYear() - 1);

    return {
      /** @type {string|null} */
      errorMsg: null,
      /** @type {boolean} */
      isLoading: true,
      /** @type {Date} */
      minDt: utils.getWeekFirstDateByDt(minDt),
      /** @type {Date} */
      maxDt: utils.getWeekLastDateByDt(nowDt),
      /** @type {WeekDateRangeItem} */
      weekDateRange: utils.getWeekDateRangeByDt(nowDt),
      /** @type {StudentWorkFolderVo[]} */
      studentWorkFolderVos: [],
      /** @type {string} */
      searchKeyword: '',
      /** @type {boolean} */
      isLoadingDialog: false,
      /** @type {boolean} */
      isShowWrongNoteCreatePopup: false,
      /** @type {StudentWorkFolderVo|null} */
      editNameStudentWorkFolderVo: null,
      /** @type {StudentWorkFolderVo|null} */
      deleteStudentWorkFolderVo: null,
      /** @type {StudentWorkFolderVo|null} */
      copyStudentWorkFolderVo: null,
      /** @type {StudentWorkFolderVo|null} */
      solveAgainStudentWorkFolderVo: null,
    };
  },
  mounted() {
    this.initDatePicker();
    this.refreshWrongNoteList();
  },
  methods: {
    async refreshWrongNoteList() {
      this.isLoading = true;

      let res;
      try {
        res = await StudentWorkService.getFolders();
        const studentWorkFolderVos = res.map((e) => StudentWorkFolderVo.fromJson(e));
        utils.debugLog(`folder response length: ${studentWorkFolderVos.length}`);
        this.studentWorkFolderVos = studentWorkFolderVos;
      } catch (e) {
        console.log(e);
        this.errorMsg = '오답 노트 리스트를 받아오는 중 오류가 발생하였습니다';
        return;
      }

      this.isLoading = false;
    },
    async initDatePicker() {
      await this.$loadScript('https://cdn.jsdelivr.net/jquery/latest/jquery.min.js');
      await this.$loadScript('https://cdn.jsdelivr.net/momentjs/latest/moment.min.js');
      await this.$loadScript('https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js');

      const startDt = this.weekDateRange.getStartDt();
      const endDt = this.weekDateRange.getEndDt();

      const pickerEl = this.$refs.searchDatePicker;
      const pickerSel = $(pickerEl);
      pickerSel.daterangepicker({
        startDate: startDt,
        endDate: endDt,
        singleDatePicker: true,
        showDropdowns: true,
        minDate: this.minDt,
        maxDate: this.maxDt,
        autoApply: true,
        locale: {
          format: "YYYY-MM-DD",
          monthNames: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        },
      });

      function refreshPickerSelectWeekStyle(picker) {
        if (picker === null || picker === undefined) return;
        const selectDt = new Date(picker.startDate.format('YYYY-MM-DD'));
        const changeStartDt = utils.getWeekFirstDateByDt(selectDt);
        const changeEndDt = utils.getWeekLastDateByDt(selectDt);
        picker.setStartDate(changeStartDt);
        picker.setEndDate(changeEndDt);
      }

      pickerSel.on('show.daterangepicker', function(ev, picker) {
        let activeCell = picker.container[0].querySelector('td.start-date');
        activeCell.parentElement.classList.add('active');
      });

      pickerSel.on('apply.daterangepicker', function(ev, picker) {
        pickerEl.dispatchEvent(new Event('change'));
        refreshPickerSelectWeekStyle(picker);
      });

      refreshPickerSelectWeekStyle(pickerSel.data('daterangepicker'));
    },
    onSearchDatePickerChangeEvent() {
      const pickerEl = this.$refs.searchDatePicker;

      const selectDt = new Date(pickerEl.value);
      const changeStartDt = utils.getWeekFirstDateByDt(selectDt);
      const changeEndDt = utils.getWeekLastDateByDt(selectDt);

      this.weekDateRange = new WeekDateRangeItem(
        changeStartDt.getTime(),
        changeEndDt.getTime(),
      );
    },
    handleEditName(studentWorkFolderVo) {
      this.editNameStudentWorkFolderVo = studentWorkFolderVo;
      this.hideDropDown();
    },
    handleDelete(studentWorkFolderVo) {
      this.deleteStudentWorkFolderVo = studentWorkFolderVo;
      this.hideDropDown();
    },
    handleCopy(studentWorkFolderVo) {
      this.copyStudentWorkFolderVo = studentWorkFolderVo;
      this.hideDropDown();
    },
    handleSolveAgain(studentWorkFolderVo) {
      this.solveAgainStudentWorkFolderVo = studentWorkFolderVo;
      this.hideDropDown();
    },
    hideDropDown() {
      const etcDropdowns = this.$refs.etcDropdown;
      etcDropdowns.forEach((e) => {
        e.hide();
      });
    },
  },
  computed: {
    searchRangeDtStr() {
      const startDt = this.weekDateRange.getStartDt();
      const endDt = this.weekDateRange.getEndDt();

      return `${utils.parseYmd(startDt)} ~ ${utils.parseYmd(endDt)}`;
    },
    searchedStudentWorkFolderVos() {
      return this.studentWorkFolderVos.filter((studentWorkFolderVo) => {
        if (this.weekDateRange.isInclude(studentWorkFolderVo.createDt) === false) return false;
        if (studentWorkFolderVo.name.includes(this.searchKeyword) === false) return false;

        return true;
      });
    },
  },
}
</script>
