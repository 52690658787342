<template>
  <div class="take-test-popup-wrong-item-wrap">
    <div class="sww-header-wrap">오답학습지</div>
    <div class="sww-body-wrap">
      <div class="bw-line-wrap">
        <h5>통과</h5>
        <div class="lw-item-list">
          <div class="lw-item">
            <p>정답률</p>
            <CustomSelect
                hidden-name="정답률"
                :onChangeValue="(value) => {this.wrongItems[0].max = value;this.handleRefreshWrongItems(value)}"
                :options="this.passOptions"
            />
            <p>% 이상</p>
          </div>
        </div>
      </div>
      <template v-for="(wrongItem, index) in this.wrongItems" :key="index">
        <div class="bw-line-wrap">
          <h5 :class="[{'hidden': index > 0}]">생성</h5>
          <div class="lw-item-list">
            <div class="lw-item">
              <p>정답률</p>
              <CustomSelect
                  hidden-name="정답률"
                  :onChangeValue="(value) => {wrongItem.max = value; this.handleRefreshWrongItems()}"
                  :options="this.getDefaultAnswerRateSelectOptions(wrongItem.max)"
              />
              <p>% 미만</p>
            </div>
            <template v-if="this.wrongItems.length > index + 1">
              <div class="lw-item">
                <CustomSelect
                    hidden-name="정답률"
                    :onChangeValue="(value) => {this.wrongItems[index + 1].max = value; this.handleRefreshWrongItems()}"
                    :options="this.getDefaultAnswerRateSelectOptions(this.wrongItems[index + 1].max)"
                />
                <p>% 이상</p>
              </div>
            </template>
            <div class="lw-item">
              <CustomSelect
                  hidden-name="배수"
                  :onChangeValue="(value) => wrongItem.multiple = value"
                  :options="this.getDefaultMultipleSelectOptions(wrongItem.multiple)"
              />
              <p>배수</p>
              <template v-if="index == 0">
                <div class="delete-btn-empty"></div>
              </template>
              <template v-else>
                <button class="delete-btn"
                        @click="() => this.handleDeleteWrongItem(index)"
                >
                  <img src="@/assets/images/close.png" alt="">
                </button>
              </template>
            </div>
          </div>
        </div>
        <template v-if="index == this.wrongItems.length - 1">
          <div class="bw-line-wrap">
            <h5 class="hidden">생성</h5>
            <div class="lw-add-wrap">
              <button @click="() => this.addWrongItem()">
                <img src="@/assets/images/plus-4.png" alt="">
              </button>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {WrongItem} from "@/items/wrong_item";
import CustomSelect from "@/components/CustomSelect.vue";

export default {
  name: 'TakeTestPopupWrongItem',
  components: {
    CustomSelect,
  },
  props: {
    isVisible: {type: Boolean, default: false},
    onChangedWrongItems: Function,
  },
  watch: {
    isVisible() {
      this.initData();
    },
    wrongItems(value) {
      this.onChangedWrongItems(value);
    },
  },
  data() {
    return {
      /** @type {WrongItem[]} */
      wrongItems: [],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.wrongItems = [new WrongItem(0, 1)];
    },
    handleRefreshWrongItems() {
      /** @type {WrongItem[]} */
      const wrongItems = this.wrongItems;
      const changeItems = [];

      let beforeMaxValue = null;
      for (let index = 0; index < wrongItems.length; index++) {
        const wrongItem = wrongItems[index];
        const maxValue = wrongItem.max;
        if (beforeMaxValue == null || beforeMaxValue > maxValue) {
          changeItems.push(wrongItem);
        }
        beforeMaxValue = maxValue;
      }

      this.wrongItems = changeItems;
    },
    addWrongItem() {
      let lastMax = 100;

      /** @type {WrongItem[]} */
      const wrongItems = this.wrongItems;
      if (wrongItems.length > 0) {
        lastMax = wrongItems[wrongItems.length - 1].max;
      }

      let nextMax = lastMax - 10;
      if (nextMax < 0) {
        this.$toast.error('0% 미만으로는 더 이상 만들 수 없습니다');
        return;
      }

      this.wrongItems.push(new WrongItem(nextMax, 1));
    },
    /**
     * @param {number} selectValue
     * @return {{value: number, name: string, isSelected: boolean}[]}
     */
    getDefaultAnswerRateSelectOptions(selectValue) {
      return Array.apply({}, Array(11)).map((_, i) => {
        const value = i * 10;
        return {value: value, name: `${value}`, isSelected: value == selectValue};
      });
    },
    getDefaultMultipleSelectOptions(selectValue) {
      return Array.apply({}, Array(3)).map((_, i) => {
        const value = i + 1;
        return {value: value, name: `${value}`, isSelected: value == selectValue};
      });
    },
    handleDeleteWrongItem(index) {
      this.wrongItems.splice(index, 1);
    },
  },
  computed: {
    passOptions() {
      /** @type {WrongItem[]} */
      const wrongItems = this.wrongItems;
      const firstWrongItem = wrongItems.length > 0 ? wrongItems[0] : null;

      return this.getDefaultAnswerRateSelectOptions(firstWrongItem?.max ?? 0);
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/values";

.take-test-popup-wrong-item-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid rgb(192, 192, 192);
  padding: 10px;
  .sww-header-wrap {
    width: 100%;
    display: flex;
    font-weight: 700;
    font-size: 17px;
    color: #000000;
  }
  .sww-body-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    .bw-line-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
      &:nth-child(2) {
        margin-top: 15px;
      }
      &>h5 {
        font-weight: 700;
        font-size: 15px;
        color: #000000;
        white-space: nowrap;
        &.hidden {
          opacity: 0;
        }
      }
      .lw-item-list {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .lw-item {
          display: flex;
          align-items: center;
          gap: 7px;
          &>p {
            font-weight: 500;
            font-size: 13px;
          }
          &>select {
            height: unset;
            padding: 5px 15px 5px 10px;
            background-size: 7px 5px;
            background-position: right 4px center;
            text-align: center;
          }
          .delete-btn {
            margin-left: 15px;
            width: 20px;
            height: 20px;
            &>img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .delete-btn-empty {
            margin-left: 15px;
            width: 20px;
          }
        }
      }
      .lw-add-wrap {
        width: 100%;
        display: flex;
        padding-right: 35px;
        &>button {
          cursor: pointer;
          width: 100%;
          height: 30px;
          background-color: #{$defaultColor};
          border-radius: 7px;
          &>img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
</style>
