<template>
  <div :class="['popup-wrap', 'wrong-note-delete-popup-wrap', { hide: !isVisible }]">
    <div class="p-shadow" @click="() => this.onCloseEvent()"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <h1>오답노트 삭제</h1>
        <p>오답노트 삭제 시 포함된 모든 문제도 함께 삭제됩니다.<br>해당 오답노트를 삭제하시겠습니까?</p>
        <div class="pi-btn-list">
          <button @click="() => this.onCloseEvent()">
            <span>취소</span>
          </button>
          <button @click="() => this.onSubmit()">
            <span>삭제</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <LoadingDialog
      :is-show="this.isLoadingDialog"
    />
  </Teleport>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog.vue";
import StudentWorkService from "@/services/studentWork.service";

export default {
  name: 'WrongNoteDeletePopup',
  components: {LoadingDialog},
  props: {
    isVisible: Boolean,
    onClose: Function,
    /** @type {StudentWorkFolderVo|null} */
    studentWorkFolderVo: Object,
    onChanged: Function,
  },
  data() {
    return {
      /** @type {boolean} */
      isLoadingDialog: false,
    };
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
    async onSubmit() {
      this.isLoadingDialog = true;
      const res = await StudentWorkService.deleteFolder(
        this.studentWorkFolderVo.id,
      );
      this.isLoadingDialog = false;
      if (!res) {
        this.$toast.error('삭제 중 오류가 발생하였습니다');
      } else {
        this.$toast.success('삭제되었습니다');
      }

      this.onClose();
      this.onChanged();
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.wrong-note-delete-popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99999999;
  padding: 20px;
  &.hide {
    display: none;
  }
  .p-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .p-body {
    position: relative;
    max-width: 400px;
    width: 100%;
    max-height: 90vh;
    margin: auto;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #DBDBDB;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
    overflow-y: auto;
    border-radius: 5px;
    padding: 30px;
    @include tablet {
      max-width: 360px;
      padding: 20px;
    }
    @include mobile {
      padding: 15px;
    }
    .pb-inner-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      &>h1 {
        font-weight: 600;
        font-size: 18px;
        color: black;
        text-align: center;
        @include tablet {
          font-size: 15px;
        }
      }
      &>p {
        margin-top: 20px;
        width: 100%;
        font-size: 15px;
        text-align: center;
        word-break: keep-all;
      }
      .pi-btn-list {
        width: 100%;
        display: flex;
        gap: 15px;
        align-items: center;
        button {
          margin-top: 30px;
          width: 100%;
          border-radius: 7px;
          background-color: #{$redColor};
          padding: 10px 15px;
          &:first-child {
            background-color: #{$grayColor};
          }
          span {
            color: white;
          }
        }
      }
    }
  }
}
</style>
