import {ProblemVo} from "@/models/problem_vo";
import {getServerMediaUrl} from "@/utils/parse";

export class StudentWorkProblemVo {
    /** @type {number} */
    id;
    /** @type {ProblemVo} */
    problemVo;
    /** @type {number} */
    order;
    /** @type {string|null} */
    answer;
    answerDt;
    /** @type {boolean|null} */
    isCurrentAnswer;
    /** @type {string|null} */
    solvingImgUrl;
    /** @type {string|null} */
    teacherSolvingImgUrl;

    constructor(
        id,
        problemVo,
        order,
        answer,
        answerDt,
        isCurrentAnswer,
        solvingImgUrl,
        teacherSolvingImgUrl,
    ) {
        this.id = id;
        this.problemVo = problemVo;
        this.order = order;
        this.answer = answer;
        this.answerDt = answerDt;
        this.isCurrentAnswer = isCurrentAnswer;
        this.solvingImgUrl = solvingImgUrl;
        this.teacherSolvingImgUrl = teacherSolvingImgUrl;
    }

    static fromJson = (json) => new StudentWorkProblemVo(
        json.id,
        ProblemVo.fromJson(json.problem),
        json.order,
        json.answer,
        json.answer_dt,
        json.is_current_answer,
        json.solving_img ? getServerMediaUrl(json.solving_img) : null,
        json.teacher_solving_img ? getServerMediaUrl(json.teacher_solving_img) : null,
    );

    isSubmitAnswer = () => this.answer != null;
    isPass() {
        const answer = this.answer;
        if (answer == null) return false;
        return answer.length == 0;
    }

    /**
     * @param {StudentWorkProblemVo[]} vos
     */
    static isCompletedByVos(vos) {
        return vos.findIndex(e => e.isSubmitAnswer() == false) < 0;
    }
}
