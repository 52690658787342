export class ProblemPatternSimpleVo {
    /** @type {number} */
    id;
    /** @type {string|null} */
    schoolType;
    /** @type {string|null} */
    schoolYear;
    /** @type {string|null} */
    term;
    /** @type {string|null} */
    problemSubject;
    /** @type {string} */
    name;
    /** @type {string|null} */
    workbookNumber;
    updateDt;
    /** @type {string|null} */
    conceptVidUrl;

    constructor(
        id,
        schoolType,
        schoolYear,
        term,
        problemSubject,
        name,
        workbookNumber,
        updateDt,
        conceptVidUrl,
    ) {
        this.id = id;
        this.schoolType = schoolType;
        this.schoolYear = schoolYear;
        this.term = term;
        this.problemSubject = problemSubject;
        this.name = name;
        this.workbookNumber = workbookNumber;
        this.updateDt = updateDt;
        this.conceptVidUrl = conceptVidUrl;
    }

    static fromJson = (json) => new ProblemPatternSimpleVo(
        json.id,
        json.school_type,
        json.school_year,
        json.term,
        json.problem_subject,
        json.name,
        json.workbook_number,
        json.update_dt,
        json.concept_vid_url,
    );
}
