<template>
  <div class="teacher-dashboard-wrap">
    <V2Wrapper>
      <div class="tdw-inner-wrap">
        <template v-if="this.errorMsg !== null">
          <ErrorMsgBox :contents="this.errorMsg"/>
        </template>
        <template v-else-if="this.isLoading">
          <LoadingBox/>
        </template>
        <template v-else>
          <div class="section-1-wrap">
            <div class="v2-base-container">
              <div class="sb-left-wrap">
                <div class="ai-chat-wrap"
                     @click="() => this.isVisibleAIAssistantPopup = true"
                >
                  <div class="mascot-img-wrap">
                    <img src="@/assets/images/sduck-mascot.png" alt="">
                    <span>AI 에스덕</span>
                  </div>
                  <div class="chat-body">
                    <img class="cb-arrow" src="@/assets/images/chat-body-arrow.png" alt=""/>
                    <div class="cb-inner-wrap">
                      <p>AI Assistant 의 메시지 :<br>선생님, 우리반 학생들은 분수의 곱셈단원을 어려워 합니다.<br>학생들의 오답상태를 확인해주세요.</p>
                    </div>
                  </div>
                </div>
                <div class="sbl-bottom-wrap">
                  <div class="sblb-left-wrap">
                    <div class="sblbl-tab-wrap">
                      <div class="tab-item active">이름</div>
                      <div class="tab-item">과제제출</div>
                      <div class="tab-item">학습단원</div>
                    </div>
                    <div class="sblbl-body-wrap">
                      <div class="sblbl-body-inner-wrap">
                        <template v-for="(studentItem, index) in this.studentItems" :key="index">
                          <div :class="['sblbl-body-item', {'active': studentItem.isActive}]"
                               @click="() => this.onClickStudentItem(index)"
                          >
                            <div class="bl-info">
                              <div class="bli-name">
                                <span>{{ studentItem.name }}</span>
                                <div :class="[`level-${studentItem.level}`]"></div>
                              </div>
                              <div :class="['bli-homework', `level-${studentItem.homeworkLevel}`]">
                                <img v-if="studentItem.homeworkLevel === -1" src="@/assets/images/homework--1.png" alt="">
                                <img v-else-if="studentItem.homeworkLevel === 0" src="@/assets/images/homework-0.png" alt="">
                                <img v-else src="@/assets/images/homework-1.png" alt="">
                              </div>
                              <div class="bli-unit">
                                <img src="@/assets/images/location.png" alt="">
                                <span>{{ studentItem.learnUnit }}</span>
                              </div>
                            </div>
                            <div class="bi-line"></div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="sblb-right-wrap">
                    <h1>학습 참여도</h1>
                    <div class="learner-dist-wrap">
                      <h5>학습자 분포</h5>
                      <div class="ld-rate-wrap">
                        <template v-for="(ldRate, index) in this.ldRates" :key="index">
                          <div :style="[{'width': `${ldRate.percent}%`}]">
                            <span>{{ ldRate.cnt }}명({{ ldRate.percent }}%)</span>
                            <img v-if="ldRate.isActive"
                                 src="@/assets/images/arrow-bottom.png"
                                 alt=""
                            >
                          </div>
                        </template>
                      </div>
                      <div class="ld-rate-info">
                        <div class="ri-item">
                          <div></div>
                          <p>빠른 학습자</p>
                        </div>
                        <div class="ri-item">
                          <div></div>
                          <p>일반 학습자</p>
                        </div>
                        <div class="ri-item">
                          <div></div>
                          <p>느린 학습자</p>
                        </div>
                      </div>
                    </div>
                    <div class="rate-wrap">
                      <div class="r-chart-item">
                        <h5>학습 이수율</h5>
                        <div class="rc-body">
                          <div class="rcb-inner-wrap">
                            <div class="rcbi-graph-wrap">
                              <DoughnutChart
                                  :chartData="this.studyCompletionRateChartData"
                                  :options="this.studyCompletionRateOption"
                              />
                            </div>
                          </div>
                          <p>{{ this.learnerDist.studyCompleteRate }}%</p>
                        </div>
                      </div>
                      <div class="r-chart-item">
                        <h5>과제 제출율</h5>
                        <div class="rc-body">
                          <div class="rcb-inner-wrap">
                            <div class="rcbi-graph-wrap">
                              <DoughnutChart
                                  :chartData="this.homeworkSubmitRateChartData"
                                  :options="this.homeworkSubmitRateOption"
                              />
                            </div>
                          </div>
                          <p>{{ this.learnerDist.homeworkSubmitRate }}%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sb-right-wrap">
                <div class="sbr-tab">
                  <div class="tab-item"
                       @click="() => this.$router.push({ name: 'TeacherSelfLearningModeResult' })"
                  >
                    <img src="@/assets/images/diagnosis-result.png" alt="">
                    <span>진단 결과</span>
                  </div>
                  <div class="tab-item"
                       @click="() => this.isVisibleLearningStatisticsPopup = true"
                  >
                    <img src="@/assets/images/learning-statistics.png" alt="">
                    <span>학습 통계</span>
                  </div>
                  <div class="tab-item">
                    <img src="@/assets/images/questions-notifications.png" alt="">
                    <span>질문&알림</span>
                  </div>
                </div>
                <div class="learning-report">
                  <h1>학습 리포트</h1>
                  <h2>{{ this.reportSubTitle }}</h2>
                  <div class="lr-list-wrap">
                    <div class="list-item">
                      <div class="item-pin"></div>
                      <p>학습 이수율 : 79%</p>
                      <span>평균 80%</span>
                      <img src="@/assets/images/arrow-bottom.png" alt="">
                    </div>
                    <div class="list-item">
                      <div class="item-pin"></div>
                      <p>과제 제출 현황 : 10/10</p>
                      <span>평균 0개</span>
                      <img src="@/assets/images/arrow-bottom-gray.png" alt="">
                    </div>
                    <div class="list-item">
                      <div class="item-pin"></div>
                      <p>정답률 : 85%</p>
                      <span>평균 대비 0개</span>
                      <img src="@/assets/images/arrow-top.png" alt="">
                    </div>
                    <div class="list-item">
                      <div class="item-pin"></div>
                      <p>학습 시간 : 200분 (3시간 20분)</p>
                      <span>평균 00분</span>
                      <img src="@/assets/images/arrow-bottom.png" alt="">
                    </div>
                  </div>
                </div>
                <div class="learning-chart-wrap">
                  <div class="inner-wrap">
                    <RadarChart
                        :chart-data="this.studyReportChartData"
                        :options="this.studyReportOption"
                        :style="[{'max-height': '100%'}]"
                    />
                  </div>
                  <div class="data-list">
                    <div class="list-item">
                      <img src="@/assets/images/line-1.png" alt="">
                      <span>평균&nbsp;&nbsp;&nbsp;</span>
                    </div>
                    <template v-if="this.selectedStudentItem">
                      <div class="list-item">
                        <img src="@/assets/images/line-2.png" alt="">
                        <span>{{ this.selectedStudentItem?.name ?? '' }}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-2-wrap">
            <div class="v2-base-container">
              <div class="s-body-wrap">
                <TitleTooltip
                    title="평가 리포트"
                    :tooltip="'' +
                      '진단평가- 학생의 해당 진도나가기전 이전 학년 교육과정을 통해 이해가 되었는지 파악후 부족한 개념 파악' +
                      '<br/>' +
                      '단원평가(AI평가) – 수업 후 학생이 이해했는지 확인, 복습개념영상, 여러 콘텐츠 활용' +
                    ''"
                />
                <div class="sb-list-wrap">
                  <template v-for="(reportItem, index) in this.reportItems" :key="index">
                    <div class="list-item">
                      <div class="li-title-wrap">
                        <h5>{{ reportItem.title }}</h5>
                        <button @click="() => this.showAnalysisDetailId = 1">
                          <span>결과 보기</span>
                          <img src="@/assets/images/arrow-right-3.png" alt="">
                        </button>
                      </div>
                      <div class="li-divider"></div>
                      <div class="li-body-wrap">
                        <div class="lib-title">
                          <span>{{ reportItem.subTitle }}</span>
                        </div>
                        <div class="lib-progress-wrap">
                          <span>{{ reportItem.submitCnt }}/{{ reportItem.totalCnt }}명</span>
                          <div class="p-bar">
                            <div class="inner-bar" :style="[{'width': `${(reportItem.submitCnt / reportItem.totalCnt) * 100}%`}]"></div>
                          </div>
                        </div>
                        <div class="lib-complete-rate-wrap">
                          <p>완료율</p>
                          <span>{{ Math.floor((reportItem.submitCnt / reportItem.totalCnt) * 100 * 10) / 10 }}%</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <button :class="['s-button', {'opened': this.isOpenLearningStatus}]"
                      @click="() => this.isOpenLearningStatus = !this.isOpenLearningStatus"
              >
                <span>학생별 학습 현황 열기</span>
                <img src="@/assets/images/arrow-bottom-2.png" alt="">
              </button>
              <template v-for="(rowCnt, index) in [1, 2]" :key="index">
                <div :class="['learning-status-wrap', {'active': this.isOpenLearningStatus}]">
                  <div class="ls-title-wrap">
                    <div class="lst-tab-list">
                      <template v-if="rowCnt === 2">
                        <div class="tl-item active">
                          <span>자가학습</span>
                        </div>
                        <div class="tl-item">
                          <span>단원평가</span>
                        </div>
                        <div class="tl-item">
                          <span>진단평가</span>
                        </div>
                      </template>
                    </div>
                    <div class="lst-dt-wrap">
                      <button class="dt-btn dtb-left"
                              @click="() => this.onChangeReportTableDt(true)"
                      >
                        <img src="@/assets/images/arrow-left-2.png" alt="">
                      </button>
                      <span>{{ this.reportTableDtStr }}</span>
                      <button v-if="!this.isReportTableDtOverWeekNumber"
                              class="dt-btn dtb-right"
                              @click="() => this.onChangeReportTableDt(false)"
                      >
                        <img src="@/assets/images/arrow-left-2.png" alt="">
                      </button>
                    </div>
                  </div>
                  <div class="ls-table-wrap">
                    <template v-for="(s2, i2) in rowCnt === 1 ? [1] : [1, 2]" :key="i2">
                      <div class="st-item">
                        <div class="ti-tr tr-header">
                          <div class="ti-td"></div>
                          <div class="ti-td">
                            <p>일<br/>07.21</p>
                          </div>
                          <div class="ti-td">
                            <p>월<br/>07.22</p>
                          </div>
                          <div class="ti-td">
                            <p>화<br/>07.23</p>
                          </div>
                          <div class="ti-td">
                            <p>수<br/>07.24</p>
                          </div>
                          <div class="ti-td">
                            <p>목<br/>07.25</p>
                          </div>
                          <div class="ti-td">
                            <p>금<br/>07.26</p>
                          </div>
                          <div class="ti-td">
                            <p>토<br/>07.27</p>
                          </div>
                        </div>
                        <template v-for="(studentName, studentIndex) in ['학생 1', '학생 2', '학생 3']" :key="studentIndex">
                          <div class="ti-tr">
                            <div class="ti-td">
                              <img class="td-smile" src="@/assets/images/smile.png" alt="">
                              <span>{{ studentName }}</span>
                            </div>
                            <template v-for="(dayItem, dayIndex) in [1, 2, 3, 4, 5, 6, 7]" :key="dayIndex">
                              <div class="ti-td">
                                <template v-if="Math.floor(Math.random() * 2) === 0">
                                  <img class="td-check-img" src="@/assets/images/close.png" alt="">
                                </template>
                                <template v-else>
                                  <img class="td-check-img" src="@/assets/images/check.png" alt="">
                                </template>
                                <div class="td-hover">
                                  <h5>2023.10.31</h5>
                                  <div class="tdh-item">
                                    <p>문제</p>
                                    <span>14개</span>
                                  </div>
                                  <div class="tdh-item">
                                    <p>동영상</p>
                                    <span>0분</span>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                  <div class="ls-desc-wrap">
                    <div class="d-title-wrap">
                      <div class="dt-item">
                        <img src="@/assets/images/check.png" alt="">
                        <span>학습함</span>
                      </div>
                      <div class="dt-item">
                        <img src="@/assets/images/close.png" alt="">
                        <span>학습 안함</span>
                      </div>
                    </div>
                    <p>학습 활동 기준 : 영상 시청, 단원별 문제 풀이, 단원평가<br>(진단평가는 학습에 포함되지 않습니다.)</p>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="section-3-wrap">
            <div class="v2-base-container">
              <TitleTooltip
                  title="대단원별 성취도 분석"
                  :tooltip="'' +
                    '초/중/고1 학생일 경우: 각 대단원의 중단원 별 학습참여율과 지도 필요 학생을 알 수 있습니다.' +
                    '<br/>' +
                    '<br/>' +
                    '고2/고3 학생일 경우: 각 과학 과목 대단원 별 학습 참여율과 지도 필요 학생을 알 수 있습니다' +
                  ''"
              />
              <div class="s-tab-list">
                <template v-for="(analysisTab, index) in this.analysisTabs" :key="index">
                  <div :class="['tab-item', {'active': index === this.selectAnalysisTabIndex}]">
                    <p>{{ analysisTab }}</p>
                    <div class="ti-divider"></div>
                  </div>
                </template>
              </div>
              <div class="s-body-list">
                <template v-for="(analysisItem, index) in this.analysisItems" :key="index">
                  <div class="list-item"
                       @click="() => this.showAnalysisDetailId = analysisItem.id"
                  >
                    <div class="li-num">
                      <p>{{ analysisItem.id }}</p>
                    </div>
                    <div class="li-title">
                      <p>{{ analysisItem.title }}</p>
                    </div>
                    <div class="li-progress-wrap">
                      <div class="pb-wrap">
                        <div class="pbw-inner" :style="[{'width': `${analysisItem.learningRate}%`}]"></div>
                      </div>
                      <p>학습 참여율</p>
                      <span>{{ analysisItem.learningRate }}%</span>
                    </div>
                    <div class="li-divider"></div>
                    <div class="li-guid-wrap">
                      <p>지도 필요학생</p>
                      <span>{{ analysisItem.needGuidCnt }}명</span>
                    </div>
                    <img class="li-arrow" src="@/assets/images/arrow-right-2.png" alt="">
                  </div>
                </template>
              </div>
              <button class="s-button">
                <img src="@/assets/images/edit.png" alt="">
                <span>학습 유도하기</span>
              </button>
            </div>
          </div>
          <div class="section-3-divider"></div>
          <div class="section-4-wrap">
            <div class="v2-base-container">
              <div class="s-title-wrap">
                <TitleTooltip
                    title="학생 분포도"
                    tooltip="툴팁 내용입니다"
                />
              </div>
              <div class="s-tab-list-wrap">
                <div class="stl-inner-wrap">
                  <template v-for="(studentDistChartTab, index) in this.studentDistChartTabs" :key="index">
                    <div :class="['tab-item', {'active': index === this.selectStudentDistChartTabIndex}]"
                         @click="() => this.selectStudentDistChartTabIndex = index"
                    >
                      <p>{{ studentDistChartTab }}</p>
                      <div></div>
                    </div>
                  </template>
                </div>
              </div>
              <template v-if="this.selectStudentDistChartTabIndex !== 0">
                <button class="s-category-btn">
                  <span>분수의 나눗셈</span>
                  <img src="@/assets/images/arrow-bottom-3.png" alt="">
                </button>
              </template>
              <div class="s-chart-wrap">
                <div class="scw-inner">
                  <div class="chart-title title-y">
                    <p>정답률</p>
                  </div>
                  <div class="chart-title title-x">
                    <p>학습량 (문제 풀이)</p>
                  </div>
                  <div class="chart-body">
                    <BubbleChart
                        :chart-data="this.studentDistChartData"
                        :options="this.studentDistChartOption"
                        :style="[{'aspect-ratio': '2.5375'}]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-5-wrap">
            <div class="v2-base-container">
              <div class="s-title-wrap">
                <TitleTooltip
                    title="학생 리스트"
                    tooltip="각 학생의 문제풀이, 정답률, 동영상 학습시간을 알 수 있습니다."
                />
                <div class="s-order-list">
                  <div class="ol-item">
                    <img src="@/assets/images/order.png" alt="">
                    <span>이름 순</span>
                  </div>
                  <div class="ol-item">
                    <img src="@/assets/images/order.png" alt="">
                    <span>정답률</span>
                  </div>
                  <div class="ol-item">
                    <img src="@/assets/images/order.png" alt="">
                    <span>문제풀이 순</span>
                  </div>
                </div>
              </div>
              <div class="s-body-wrap">
                <template v-for="(studentListItem, index) in this.studentListItems" :key="index">
                  <div class="sb-item">
                    <div class="i-thumb-box">
                      <img :src="studentListItem.thumbnailUrl" alt="">
                    </div>
                    <div class="i-body">
                      <div class="ib-title">
                        <p>{{ studentListItem.name }}</p>
                        <img src="@/assets/images/arrow-right-4.png" alt="">
                      </div>
                      <div class="ib-contents">
                        <div class="c-item">
                          <p>문제 풀이</p>
                          <span>{{ studentListItem.problemCnt }}개</span>
                        </div>
                        <div class="c-item">
                          <p>정답률</p>
                          <span>{{ studentListItem.answerRate }}%</span>
                        </div>
                        <div class="c-item">
                          <p>동영상 학습</p>
                          <span>{{ studentListItem.videoLearnMinute }}분</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </V2Wrapper>
  </div>

  <AIAssistantPopup
    :is-visible="this.isVisibleAIAssistantPopup"
    :on-close="() => this.isVisibleAIAssistantPopup = false"
  />
  <AnalysisDetailPopup
    :is-visible="this.showAnalysisDetailId !== null"
    :on-close="() => this.showAnalysisDetailId = null"
  />
  <LearningStatisticsPopup
    :is-visible="this.isVisibleLearningStatisticsPopup"
    :on-close="() => this.isVisibleLearningStatisticsPopup = false"
  />
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BarChart, DoughnutChart, RadarChart, BubbleChart } from 'vue-chart-3';
import TitleTooltip from "@/components/TitleTooltip.vue";
import AIAssistantPopup from "@/components/AIAssistantPopup.vue";
import AnalysisDetailPopup from "@/components/AnalysisDetailPopup.vue";
import LearningStatisticsPopup from "@/components/LearningStatisticsPopup.vue";
import {StudentHomeworkProgressType, StudentProgressType} from "@/utils/enums";
import utils from "@/utils";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import {mapActions, mapGetters} from "vuex";
import LoadingBox from "@/components/LoadingBox.vue";
import AcademyService from "@/services/academy.service";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  name: 'TeacherDashboard',
  components: {
    LoadingBox,
    ErrorMsgBox,
    LearningStatisticsPopup,
    AnalysisDetailPopup,
    AIAssistantPopup,
    BubbleChart,
    TitleTooltip,
    V2Wrapper,
    BarChart,
    DoughnutChart,
    RadarChart,
  },
  data() {
    const sampleNames = [
      '강기복',
      '도레미',
      '라분발',
    ];
    const sampleThumbnailUrls = [
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1520626337972-ebf863448db6?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ];
    const studentProgressTypes = [
      StudentProgressType.slower,
      StudentProgressType.normal,
      StudentProgressType.faster,
    ];
    const studentHomeworkProgressTypes = [
      StudentHomeworkProgressType.none,
      StudentHomeworkProgressType.processing,
      StudentHomeworkProgressType.completed,
    ];
    const learnUnits = [1, 2, 3, 4];
    const nowDt = new Date();

    return {
      /** @type {string|null} */
      errorMsg: null,
      /** @type {boolean} */
      isLoading: true,
      isVisibleLearningStatisticsPopup: false,
      isOpenLearningStatus: false,
      isVisibleAIAssistantPopup: false,
      showAnalysisDetailId: null,
      // studentItems: Array.from({length: 30}, (_, i) => {
      //   return {
      //     name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
      //     level: studentProgressTypes[Math.floor(Math.random() * studentProgressTypes.length)],
      //     homeworkLevel: studentHomeworkProgressTypes[Math.floor(Math.random() * studentHomeworkProgressTypes.length)],
      //     learnUnit: learnUnits[Math.floor(Math.random() * learnUnits.length)],
      //   };
      // }),
      studentItems: [],
      reportItems: [
        {title: '진단평가', subTitle: '소인수 분해 / 정수와 유리수', submitCnt: 1, totalCnt: 2},
        {title: 'AI 평가', subTitle: '소인수 분해 / 정수와 유리수', submitCnt: 2, totalCnt: 2},
      ],
      reportTableDt: nowDt,
      analysisTabs: [
        '수학',
        '과학',
        '사회1',
        '사회2',
        '중등 기초수학',
      ],
      selectAnalysisTabIndex: 0,
      analysisItems: [
        {id: 1, title: '소인수분해 / 정수와 유리수', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
        {id: 2, title: '문자와 식', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
        {id: 3, title: '좌표평면과 그래프', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
        {id: 4, title: '기본도형', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
        {id: 5, title: '평면도형', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
        {id: 6, title: '입체도형', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
        {id: 7, title: '자료의 정리와 해석', learningRate: Math.floor(Math.random() * 101), needGuidCnt: Math.floor(Math.random() * 10)},
      ],
      studentDistChartTabs: [
        '과목 전체',
        '물리',
        '화학',
        '생명',
        '지구과학',
      ],
      selectStudentDistChartTabIndex: 0,
      studentDistDatas: [
        {id: 1, name: '권현우1', answerRate: Math.floor(Math.random() * 101), problemCnt: Math.floor(Math.random() * 101), recentLearnDate: '2023-10-12 09:23'},
        {id: 2, name: '권현우2', answerRate: Math.floor(Math.random() * 101), problemCnt: Math.floor(Math.random() * 101), recentLearnDate: '2023-10-12 09:23'},
        {id: 3, name: '권현우3', answerRate: Math.floor(Math.random() * 101), problemCnt: Math.floor(Math.random() * 101), recentLearnDate: '2023-10-12 09:23'},
        {id: 4, name: '권현우4', answerRate: Math.floor(Math.random() * 101), problemCnt: Math.floor(Math.random() * 101), recentLearnDate: '2023-10-12 09:23'},
      ],
      studentListItems: Array.from({length: 6}, (_, i) => {
        return {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
          answerRate: Math.floor(Math.random() * 101),
          videoLearnMinute: Math.floor(Math.random() * 101),
        };
      }),
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.setIsSelectV2(true);

      const academyUserId = this.getUser?.academy_user?.id;
      if (academyUserId === null) {
        this.showErrorMsg('학원 ID를 알 수 없습니다');
        return;
      }

      let selectedAcademyClassId = this.getSelectedAcademyClassId;
      if (selectedAcademyClassId === null) {
        const maclItemVos = await AcademyService.getMyAcademyClassList();
        if (maclItemVos === null) {
          this.showErrorMsg('반 리스트를 불러오는 중 오류가 발생하였습니다');
          return;
        }

        if (maclItemVos.length === 0) {
          this.showErrorMsg('반 리스트를 불러오는 중 오류가 발생하였습니다');
          return;
        }

        selectedAcademyClassId = maclItemVos[0].id;
        await this.changeAcademyClassId(selectedAcademyClassId);
      }

      const res = await AcademyService.getTeacherDashboardData(selectedAcademyClassId);
      if (res === null) {
        this.errorMsg = '데이터를 불러오는 중 오류가 발생하였습니다';
        this.isLoading = false;
        return;
      }
      utils.debugLog(res);
      this.studentItems = res.student_items ?? [];

      this.isLoading = false;
    },
    showErrorMsg(errorMsg) {
      this.errorMsg = errorMsg;
      this.isLoading = false;
    },
    onClickStudentItem(selectIndex) {
      const studentItems = this.studentItems;
      for (let index = 0; index < studentItems.length; index++) {
        studentItems[index].isActive = selectIndex === index;
      }
    },
    onChangeReportTableDt(isPrev) {
      const reportTableDt = this.reportTableDt;

      if (isPrev) {
        this.reportTableDt = new Date(reportTableDt.setDate(reportTableDt.getDate() - 7));
      } else {
        this.reportTableDt = new Date(reportTableDt.setDate(reportTableDt.getDate() + 7));
      }
    },
    ...mapActions('selectV2', ['setIsSelectV2']),
    ...mapActions('classStore', ['changeAcademyClassId']),
  },
  computed: {
    isReportTableDtOverWeekNumber() {
      const reportTableDt = this.reportTableDt;
      const year = reportTableDt.getFullYear();
      const month = reportTableDt.getMonth();
      const weekNumber = utils.getWeekNumberOfMonth(reportTableDt);

      const nowDt = new Date();
      const nowYear = nowDt.getFullYear();
      const nowMonth = nowDt.getMonth();
      const nowWeekNumber = utils.getWeekNumberOfMonth(nowDt);

      return year >= nowYear
          && month >= nowMonth
          && weekNumber >= nowWeekNumber;
    },
    reportTableDtStr() {
      const reportTableDt = this.reportTableDt;
      const weekNumber = utils.getWeekNumberOfMonth(reportTableDt);

      return `${reportTableDt.getMonth() + 1}월 ${weekNumber}주차`;
    },
    reportSubTitle() {
      const selectedStudentItem = this.selectedStudentItem;
      if (selectedStudentItem) {
        return `‘${selectedStudentItem.name}’ 학생의 학습 현황`;
      }

      return '반 전체 학생의 리포트';
    },
    selectedStudentItem() {
      const studentItems = this.studentItems;
      for (let index = 0; index < studentItems.length; index++) {
        const studentItem = studentItems[index];
        if (studentItem.isActive) {
          return studentItem;
        }
      }
      return null;
    },
    ldRates() {
      const studentItems = this.studentItems;

      const totalCnt = studentItems.length;

      const fasterCnt = studentItems.filter(i => i.level === StudentProgressType.faster).length;
      const normalCnt = studentItems.filter(i => i.level === StudentProgressType.normal).length;
      const slowerCnt = studentItems.filter(i => i.level === StudentProgressType.slower).length;

      const fasterPer = Math.round(fasterCnt / totalCnt * 100);
      const normalPer = Math.round(normalCnt / totalCnt * 100);
      const slowerPer = 100 - fasterPer - normalPer;

      let isFasterActive = false;
      let isNormalActive = false;
      let isSlowerActive = false;
      for (let index = 0; index < studentItems.length; index++) {
        const studentItem = studentItems[index];
        if (studentItem.isActive) {
          if (studentItem.level === StudentProgressType.faster) {
            isFasterActive = true;
          } else if (studentItem.level === StudentProgressType.normal) {
            isNormalActive = true;
          } else if (studentItem.level === StudentProgressType.slower) {
            isSlowerActive = true;
          }
        }
      }

      return [
        {isActive: isFasterActive, percent: fasterPer, cnt: fasterCnt},
        {isActive: isNormalActive, percent: normalPer, cnt: normalCnt},
        {isActive: isSlowerActive, percent: slowerPer, cnt: slowerCnt},
      ];
    },
    studyCompletionRateChartData() {
      const studyCompleteRate = this.learnerDist.studyCompleteRate;

      return {
        labels: [
            '이수완료자',
            '이수미완료자',
        ],
        datasets: [
          {
            data: [studyCompleteRate, 100 - studyCompleteRate],
            backgroundColor: ['#5DF0D6', '#1CD7BB'],
          },
        ],
      };
    },
    studyCompletionRateOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    homeworkSubmitRateChartData() {
      const homeworkSubmitRate = this.learnerDist.homeworkSubmitRate;

      return {
        labels: [
            '제출완료자',
            '제출미완료자',
        ],
        datasets: [
          {
            data: [homeworkSubmitRate, 100 - homeworkSubmitRate],
            backgroundColor: ['#FEB92E', '#FC6530'],
          },
        ],
      };
    },
    homeworkSubmitRateOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    studyReportChartData() {
      const selectedStudentItem = this.selectedStudentItem;

      return {
        labels: [
          '물리',
          '화학',
          '생물',
          '지구과학',
        ],
        datasets: [
          {
            label: selectedStudentItem?.name ?? '',
            data: selectedStudentItem ? [
              Math.floor(Math.random() * 101),
              Math.floor(Math.random() * 101),
              Math.floor(Math.random() * 101),
              Math.floor(Math.random() * 101),
            ] : [
              0,
              0,
              0,
              0,
            ],
            fill: false,
            borderColor: '#008ED5',
            backgroundColor: '#008ED5',
          },
          {
            label: '평균',
            data: [
              45,
              30,
              70,
              55,
            ],
            fill: false,
            borderColor: '#F8B633',
            backgroundColor: '#F8B633',
          },
        ],
      };
    },
    studyReportOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
        scales: {
          r: {
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
              font: {
                size: 7,
                family: 'NanumSquare',
              },
            },
            grid: {
              circular: true,
            },
          },
        },
      };
    },
    learnerDist() {
      const studentItems = this.studentItems;
      const totalCnt = studentItems.length;
      const fastCnt = studentItems.filter(e => e.level === 1).length;
      const fastPer = Math.round(((fastCnt / totalCnt) * 100 * 10)) / 10;
      const generalCnt = studentItems.filter(e => e.level === 0).length;
      const generalPer = Math.round(((generalCnt / totalCnt) * 100 * 10)) / 10;
      const slowCnt = studentItems.filter(e => e.level === -1).length;
      const slowPer = 100 - fastPer - generalPer;

      return {
        totalCnt: totalCnt,
        fastCnt: fastCnt,
        fastPer: fastPer,
        generalCnt: generalCnt,
        generalPer: generalPer,
        slowCnt: slowCnt,
        slowPer: slowPer,
        studyCompleteRate: 45,
        homeworkSubmitRate: 75,
      };
    },
    studentDistChartData() {
      const studentDistDatas = this.studentDistDatas;

      return {
        datasets: studentDistDatas.map((data) => {
          return {
            label: data.id,
            data: [
              {
                y: data.answerRate,
                x: data.problemCnt,
                r: 9,
              },
            ],
            backgroundColor: '#F9D286',
            borderColor: '#F8B633',
          };
        }),
      };
    },
    studentDistChartOption() {
      const studentDistDatas = this.studentDistDatas;

      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            enabled: false,
            external: function (context) {
              let tooltipEl = document.getElementById('chartjs-tooltip');

              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                document.body.appendChild(tooltipEl);
              }

              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.top = '50%';
                tooltipEl.style.left = '50%';
                tooltipEl.style.opacity = 0;
                tooltipEl.style.padding = '0';
                return;
              }

              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              const dataPoints = tooltipModel.dataPoints;
              if (dataPoints.length === 0) return;

              const dataPoint = dataPoints[0];
              const dataset = dataPoint.dataset;
              if (!dataset) return;

              const label = dataset.label;
              const datas = dataset.data;
              if (!label || !datas || datas.length === 0) return;

              const data = datas[0];
              if (!data) return;

              const dataId = label;
              const studentDistData = studentDistDatas.find(e => e.id === dataId);
              if (!studentDistData) return;

              tooltipEl.innerHTML = `
<div class="teacher-dashboard-sdc-tooltip-wrap">
    <div class="t-name-wrap">
        <img src="https://firebasestorage.googleapis.com/v0/b/sduck-368307.appspot.com/o/smile.png?alt=media&token=f04d26f4-87d6-4080-9cf8-adb543c3e3e6" alt="">
        <p>${studentDistData.name}</p>
    </div>
    <div class="t-divider"></div>
    <div class="t-body-wrap">
        <div class="tb-item">
            <p>문제 풀이</p>
            <span class="active">${studentDistData.problemCnt}개</span>
        </div>
        <div class="tb-item">
            <p>정답률</p>
            <span>${studentDistData.answerRate}%</span>
        </div>
        <div class="tb-item">
            <p>최근 학습</p>
            <span>${studentDistData.recentLearnDate}</span>
        </div>
    </div>
</div>
              `;

              const position = context.chart.canvas.getBoundingClientRect();
              const resPosTop = position.top + window.pageYOffset + tooltipModel.caretY;
              const resPosLeft = position.left + window.pageXOffset + tooltipModel.caretX;
              tooltipEl.style.top = tooltipModel.caretY > (position.height / 2)
                  ? `${resPosTop - tooltipEl.clientHeight}px`
                  : `${resPosTop}px`;
              tooltipEl.style.left = tooltipModel.caretX > (position.width / 2)
                  ? `${resPosLeft - tooltipEl.clientWidth}px`
                  : `${resPosLeft}px`;
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.padding = '0';
              tooltipEl.style.pointerEvents = 'none';
            },
          },
        },
        scales: {
          y: {
            min: 0,
            max: 100,
            ticks: {
              stepSize: 20,
              font: {
                size: 11,
                family: 'Inter',
              },
              callback: function(value, index, ticks) {
                return `${value}%`;
              },
              color: '#008ED5',
            },
          },
          x: {
            min: 0,
            ticks: {
              stepSize: 15,
              font: {
                size: 11,
                family: 'Inter',
              },
              callback: function(value, index, ticks) {
                return `${value} 개`;
              },
              color: '#333333',
            },
          },
        },
      };
    },
    ...mapGetters('auth', [
      'getUser',
    ]),
    ...mapGetters('classStore', [
      'getSelectedAcademyClassId',
    ]),
    ...mapGetters('selectV2', [
      'isSelectV2',
    ]),
  },
}
</script>
