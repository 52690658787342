<template>
  <div class="student-learning-history-detail-wrap">
    <V2Wrapper>
      <div class="slhdw-inner-wrap">
        <template v-if="this.errorMsg !== null">
          <ErrorMsgBox :contents="this.errorMsg"/>
        </template>
        <template v-else-if="this.isLoading">
          <div class="iw-loading">
            <b-spinner
                style="width: 3rem; height: 3rem;"
                label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template v-else>
          <template v-if="this.isCompleted">
            <StudentAnswerDetail
              :student-work-id="this.studentWorkId"
              :header-title="this.headerTitle"
            />
          </template>
          <template v-else>
            <StudentProblemSolving
              :student-work-id="this.studentWorkId"
              :is-show-concept-vid="this.isShowConceptVid"
              :init-student-work-problem-vos="this.studentWorkProblemVos"
              :header-title="this.headerTitle"
            />
          </template>
        </template>
      </div>
    </V2Wrapper>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import {mapGetters} from "vuex";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import studentWorkService from "@/services/studentWork.service";
import {StudentWorkProblemVo} from "@/models/student_work_problem_vo";
import StudentLearningHistoryDetailBottomPopup from "@/components/StudentLearningHistoryDetailBottomPopup.vue";
import YoutubeEmbedPopup from "@/components/YoutubeEmbedPopup.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";
import StudentProblemSolving from "@/components/StudentProblemSolving.vue";
import StudentAnswerDetail from "@/components/StudentAnswerDetail.vue";

export default {
  name: 'StudentLearningHistoryDetail',
  components: {
    StudentAnswerDetail,
    StudentProblemSolving,
    LoadingDialog,
    YoutubeEmbedPopup,
    StudentLearningHistoryDetailBottomPopup,
    ErrorMsgBox,
    V2Wrapper,
  },
  data() {
    return {
      /** @type {string|null} */
      errorMsg: null,
      /** @type {boolean} */
      isLoading: true,
      /** @type {string} */
      headerTitle: '',
      /** @type {string|null} */
      studentWorkId: null,
      /** @type {StudentWorkProblemVo[]} */
      studentWorkProblemVos: [],
      /** @type {boolean} 개념 영상 여부 */
      isShowConceptVid: false,
      /** @type {boolean} 완료된 숙제 여부 */
      isCompleted: false,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      const {studentWorkId} = this.$route.params;
      this.studentWorkId = studentWorkId;
      const academyUserId = this.getStudentAcademyUserId;
      if (!academyUserId) {
        this.errorMsg = '학원 사용자 ID를 확인할 수 없습니다.';
        return;
      }

      let res;
      try {
        res = await studentWorkService.getStudentWork(studentWorkId);
      } catch (e) {
        console.log(e);
        this.errorMsg = '문제 정보를 불러오는 중 오류가 발생하였습니다.';
        return;
      }
      this.headerTitle = res.name;

      const connectStudentId = res.student?.id ?? null;
      if (!connectStudentId) {
        this.errorMsg = '연결된 학생 정보를 불러오는 중 오류가 발생하였습니다.';
        return;
      } else if (academyUserId != connectStudentId) {
        this.errorMsg = '출제된 학생만 문제 풀이가 가능합니다.';
        return;
      }

      const worksheetObj = res.worksheet ?? res.workbook;
      if (!worksheetObj) {
        this.errorMsg = '학습지 정보를 불러오는 중 오류가 발생하였습니다.';
        return;
      }
      this.isShowConceptVid = worksheetObj.is_show_concept_vid ?? false;
      const studentWorkProblemVos = (res.student_work_problem ?? []).map(e => StudentWorkProblemVo.fromJson(e));
      this.studentWorkProblemVos = studentWorkProblemVos;
      this.isCompleted = StudentWorkProblemVo.isCompletedByVos(studentWorkProblemVos);

      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters('auth', [
      'getStudentAcademyUserId',
    ]),
  },
}
</script>
