<template>
  <div :class="['popup-wrap', 'wrong-note-edit-name-popup-wrap', { hide: !isVisible }]">
    <div class="p-shadow" @click="this.onCloseEvent"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <h1>오답노트 이름 수정</h1>
        <input
          ref="input"
          v-model="this.name"
          maxlength="50"
          placeholder="오답노트 이름을 입력해주세요"
        >
        <button @click="() => this.onSubmit()">
          <span>수정하기</span>
        </button>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <LoadingDialog
      :is-show="this.isLoadingDialog"
    />
  </Teleport>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog.vue";
import StudentWorkService from "@/services/studentWork.service";

export default {
  name: 'WrongNoteEditNamePopup',
  components: {LoadingDialog},
  props: {
    isVisible: Boolean,
    onClose: Function,
    /** @type {StudentWorkFolderVo|null} */
    studentWorkFolderVo: Object,
    onChanged: Function,
  },
  data() {
    return {
      /** @type {string} */
      name: '',
      /** @type {boolean} */
      isLoadingDialog: false,
    };
  },
  watch: {
    studentWorkFolderVo(data) {
      this.name = this.studentWorkFolderVo?.name ?? '';
    },
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
    async onSubmit() {
      const name = this.name;
      if (name.length === 0) {
        this.$toast.warning('오답노트 이름을 입력해주세요');
        return;
      }

      this.isLoadingDialog = true;
      const res = await StudentWorkService.editStudentWorkFolder(
        this.studentWorkFolderVo.id,
        name,
      );
      this.isLoadingDialog = false;
      if (!res) {
        this.$toast.error('오답노트 수정 중 오류가 발생하였습니다');
      } else {
        this.$toast.success('수정되었습니다');
      }

      this.onClose();
      this.onChanged();
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.wrong-note-edit-name-popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99999999;
  padding: 20px;
  &.hide {
    display: none;
  }
  .p-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .p-body {
    position: relative;
    max-width: 400px;
    width: 100%;
    max-height: 90vh;
    margin: auto;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #DBDBDB;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, .25);
    overflow-y: auto;
    border-radius: 5px;
    padding: 30px;
    @include tablet {
      max-width: 360px;
      padding: 20px;
    }
    @include mobile {
      padding: 15px;
    }
    .pb-inner-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      &>h1 {
        font-weight: 600;
        font-size: 18px;
        color: black;
        @include tablet {
          font-size: 15px;
        }
      }
      input {
        margin-top: 40px;
        width: 100%;
        padding: 10px 15px;
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: 7px;
        font-size: 15px;
        outline: none;
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
        &::placeholder {
          color: #CCCCCC;
        }
        &:focus-visible {
          border-color: #{$defaultColor};
        }
      }
      button {
        margin-top: 30px;
        width: 100%;
        border-radius: 7px;
        background-color: #{$defaultColor};
        padding: 10px 15px;
        span {
          color: white;
        }
      }
    }
  }
}
</style>
