<template>
  <div :class="['popup-wrap', 'analysis-detail-popup-wrap', { hide: !this.isVisible }]">
    <div class="p-shadow" @click="this.onCloseEvent"></div>
    <div class="p-body">
      <div class="pb-inner-wrap">
        <h1>역학적 시스템과 안전</h1>
        <div class="header-graph-list">
          <div class="gl-item gl-learning-rate-wrap">
            <div class="gli-title-wrap">
              <p>학습 참여율</p>
              <b-button class="s-tooltip"
                        v-b-tooltip.hover.html="{
                          title: '해당 대단원 또는 중단원 별 학생들의 학습 참여율(과제를 참여한 학생 / 전체 학생)을 알 수 있습니다.',
                        }"
              >
                <img src="@/assets/images/tooltip.png" alt="">
              </b-button>
            </div>
            <div class="graph-line-wrap">
              <div class="graph-wrap">
                <div class="graph-outer-wrap">
                  <div class="graph-inner-wrap">
                    <DoughnutChart
                        :chartData="this.learningRateChartData"
                        :options="this.learningRateOption"
                        :style="[{'aspect-ratio': '1'}]"
                    />
                  </div>
                </div>
              </div>
              <div class="graph-desc-wrap">
                <div class="gd-item">
                  <div class="gdi-icon"></div>
                  <h5>참여</h5>
                  <p>9명</p>
                  <span>(90%)</span>
                </div>
                <div class="gd-item">
                  <div class="gdi-icon"></div>
                  <h5>미참여</h5>
                  <p>1명</p>
                  <span>(10%)</span>
                </div>
              </div>
            </div>
          </div>
          <div class="gl-item gl-learning-state-wrap">
            <div class="gli-title-wrap">
              <p>학습 상태</p>
              <b-button class="s-tooltip"
                        v-b-tooltip.hover.html="{
                          title: '해당 대단원 또는 중단원 별 학생들의 학습 상태를 알 수 있습니다.',
                        }"
              >
                <img src="@/assets/images/tooltip.png" alt="">
              </b-button>
            </div>
            <div class="graph-line-wrap">
              <div class="graph-wrap">
                <div class="graph-outer-wrap">
                  <div class="graph-inner-wrap">
                    <DoughnutChart
                        :chartData="this.learningStatusChartData"
                        :options="this.learningStatusChartOption"
                        :style="[{'aspect-ratio': '1'}]"
                    />
                  </div>
                </div>
              </div>
              <div class="graph-desc-wrap">
                <div class="gd-item">
                  <div class="gdi-icon"></div>
                  <h5>성취 완료</h5>
                  <p>5명</p>
                  <span>(50%)</span>
                </div>
                <div class="gd-item">
                  <div class="gdi-icon"></div>
                  <h5>학습 진행중</h5>
                  <p>2명</p>
                  <span>(20%)</span>
                </div>
                <div class="gd-item">
                  <div class="gdi-icon"></div>
                  <h5>부족한 부분 발견</h5>
                  <p>2명</p>
                  <span>(20%)</span>
                </div>
                <div class="gd-item">
                  <div class="gdi-icon"></div>
                  <h5>학습 이력 없음</h5>
                  <p>1명</p>
                  <span>(10%)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vulnerable-wrap">
          <h2>🚨 취약한 학습 요소</h2>
          <div class="vw-list-wrap">
            <template v-for="(vulnerableItem, index) in this.vulnerableItems" :key="index">
              <div class="list-item">
                <p><strong>{{ vulnerableItem.studentCnt }}</strong>명의 학생이 취약해요</p>
                <span>{{ vulnerableItem.title }}</span>
              </div>
            </template>
          </div>
        </div>
        <template v-for="(listItem, index) in [
          this.noLearningHistoryStudents,
          this.discoveredMissingStudents,
          this.learningProgressStudents,
        ]" :key="index">
          <div class="student-list-wrap">
            <div class="sl-title-wrap">
              <div class="slt-icon" :style="[{'background-color': index === 0 ? '#D9D9D9' : index === 1 ? '#FF6854' : '#008ED5'}]"></div>
              <p>{{ index === 0 ? '학습 이력 없음' : index === 1 ? '부족한 부분 발견' : '학습 진행 중' }} ({{ listItem.length }}명)</p>
            </div>
            <div class="sl-list-wrap">
              <template v-for="(studentItem, index) in listItem" :key="index">
                <div class="sll-item"
                     @click="() => this.$router.push({ name: 'TeacherSelfLearningModeResult' })"
                >
                  <div class="item-img-box">
                    <img :src="studentItem.thumbnailUrl" alt="">
                  </div>
                  <p>{{ studentItem.name }}</p>
                  <span>{{ studentItem.problemCnt }}문항</span>
                  <img class="item-arrow" src="@/assets/images/arrow-right-5.png" alt="">
                </div>
              </template>
            </div>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
import {DoughnutChart} from "vue-chart-3";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
  components: {
    DoughnutChart
  },
  props: {
    isVisible: Boolean,
    onClose: Function,
  },
  data() {
    const sampleNames = [
      '강기복',
      '도레미',
      '라분발',
    ];
    const sampleThumbnailUrls = [
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1520626337972-ebf863448db6?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ];

    return {
      vulnerableItems: [
        {studentCnt: Math.floor(Math.random() * 11), title: '옥상에서 떨어트린 물동'},
        {studentCnt: Math.floor(Math.random() * 11), title: '자유낙하운동'},
        {studentCnt: Math.floor(Math.random() * 11), title: '두물체간의 충격량계산'},
      ],
      noLearningHistoryStudents: [
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: 0,
        },
      ],
      discoveredMissingStudents: [
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
        },
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
        },
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
        },
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
        },
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
        },
      ],
      learningProgressStudents: [
        {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          problemCnt: Math.floor(Math.random() * 101),
        },
      ],
    };
  },
  watch: {
    isVisible() {
    },
  },
  mounted() {
  },
  methods: {
    onCloseEvent() {
      this.onClose();
    },
  },
  computed: {
    learningRateChartData() {
      return {
        labels: [
          '참여',
          '미참여',
        ],
        datasets: [
          {
            data: [9, 1],
            backgroundColor: ['#008ED5', '#A4E1FF'],
          },
        ],
      };
    },
    learningRateOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    learningStatusChartData() {
      return {
        labels: [
          '성취 완료',
          '학습 진행중',
          '부족한 부분 발견',
          '학습 이력 없음',
        ],
        datasets: [
          {
            data: [
              5,
              2,
              2,
              1,
            ],
            backgroundColor: [
              '#006EA5',
              '#008ED5',
              '#FF6854',
              '#797979',
            ],
          },
        ],
      };
    },
    learningStatusChartOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
  },
}
</script>
