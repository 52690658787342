import {isIncludeBetweenDate} from "@/utils/date";

export class WeekDateRangeItem {
    /** @type {number} 주간 시작 일시 시간 */
    startTime;
    /** @type {number} 주간 종료 일시 시간 */
    endTime;

    constructor(
        startTime,
        endTime,
    ) {
        this.startTime = startTime;
        this.endTime = endTime;
    }

    /** @type {Date} */
    getStartDt = () => new Date(this.startTime);

    /** @type {Date} */
    getEndDt = () => new Date(this.endTime);

    isInclude = (targetDt) => isIncludeBetweenDate(targetDt, this.getStartDt(), this.getEndDt());
}
