<template>
  <div class="mypage-teacher-wrap">
    <MypageList
        :selectTabIndex="this.selectTabIndex"
        :tabs="this.tabs"
        :onTabClick="onChangeTab"
        :filterData="this.filterData"
        :listData="this.listData"
        :paginateData="this.paginateData"
        :onPageChange="this.changePage"
        :isLoading="this.isLoading"
    >
      <template v-slot:tabBody>
        <div :class="['select-list', {'open': checkedWorkbooks.length > 0}]">
          <h1>교재 {{ checkedWorkbooks.length }}개 선택됨</h1>

          <div class="btn-list">
            <div class="btn-item" @click="() => {isOpenDeletePopup = true}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="delete" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 16px; color: white;"><path fill="silver" d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zm10.833-12.5h-2.916l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333z"></path></svg>
              <span>선택 삭제</span>
            </div>
            <div class="close-btn" @click="onClickCloseBtn">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(159, 159, 159);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
            </div>
          </div>
        </div>
      </template>
    </MypageList>
  </div>

  <Popup
      :isTitleExclamationMark="true"
      :popupTitle="'주의'"
      :isVisible="isOpenDeletePopup"
      :onClose="() => {isOpenDeletePopup = false}"
      :onConfirm="onDeleteWorkbooks"
      :onCancel="() => {}"
  >
    <template v-slot:bodyContents>
      <div>교재를 삭제하면 학생에게 출제한 내역이 모두 사라지고<br>다시 복구가 불가능합니다.<br>정말 삭제하시겠습니까?</div>
    </template>
  </Popup>

  <TakeTestPopup
      :isVisible="!!this.takeTestWorkbook"
      :workbookId="this.takeTestWorkbook?.id"
      :onClose="() => this.takeTestWorkbook = null"
      :subTitle="this.takeTestWorkbook?.name ?? ''"
  />
  <DownloadPopup
      :is-visible="!!this.downloadWorkbook"
      :workbook="this.downloadWorkbook"
      :on-close="() => this.downloadWorkbook = null"
  />
</template>

<script>
import MypageList from '../../../../components/MypageList.vue';
import models from '../../../../models';
import WorkbookService from '../../../../services/workbook.service';
import utils from '../../../../utils';
import Popup from '@/components/Popup.vue'
import TakeTestPopup from '@/components/TakeTestPopup.vue'
import DownloadPopup from "@/components/DownloadPopup.vue";
import {EditType} from "@/utils/enums";

export default {
  name: "MypageTeacherClassPreWorkbook",
  components: {
    DownloadPopup,
    MypageList,
    Popup,
    TakeTestPopup,
  },
  data() {
    return {
      selectTabIndex: 0,
      tabs: [
        {title: '내 교재'},
        {title: '시중교재'},
      ],

      schoolTypes: models.schoolTypeList(true),
      tags: models.bookTagList(true),

      selectSchoolType: null,
      selectTag: null,
      searchKeyword: null,

      isCommercial: true, // 시중교재 여부
      isTextbook: false, // 교과서 여부

      workbooks: [], // 내 교재

      isOpenDeletePopup: false,
      utils,
      isDownloading: false,

      takeTestWorkbook: null,
      downloadWorkbook: null,

      isLoading: true,
      paginateData: {
        'size': 10,
        'page': 1,
        'totalPage': 1,
        'maxVisiblePage': 1 + 5 + 5,
      },
    };
  },
  watch: {
    selectSchoolType() { this.refreshList() },
    selectTag() { this.refreshList() },
    searchKeyword() { this.refreshList() },
    isCommercial() { this.refreshList() },
    isTextbook() { this.refreshList() },
  },
  mounted() {
    this.refreshList();
  },
  methods: {
    redirectCopy(id) {
      this.$router.push({ name: utils.isV2Url(this) ? 'TeacherWorkbookEdit' : 'WorkbookEdit', query: {'id': id, 'type': EditType.preWorkbook} })
    },
    redirectEdit(id) {
      this.$router.push({ name: utils.isV2Url(this) ? 'TeacherWorkbookEdit' : 'WorkbookEdit', query: {'id': id} })
    },
    async onDeleteWorkbookTarget(wb) {
      this.workbooks.forEach(i => i.isChecked = i.id == wb.id)
      this.isOpenDeletePopup = true
    },
    async onDeleteWorkbooks() {
      if (this.checkedWorkbooks.length == 0) {
        this.$toast.warning('삭제할 학습지를 선택해주세요')
      } else {
        await WorkbookService.deleteWorkbook({
          id_list: this.checkedWorkbooks.map(i => i.id)
        })
        this.$toast.success('해당 학습지들이 삭제되었습니다')
        await this.refreshList();
      }
    },
    onClickCloseBtn() {
      this.workbooks.forEach(i => i.isChecked = false)
    },
    onChangeItem(item) {
      const findItem = this.workbooks.find(i => i.id == item.id)
      if (findItem) {
        findItem.isChecked = !findItem.isChecked
      }
    },
    onChangeTab(index) {
      this.selectTabIndex = index;
      this.selectSchoolType = null;
      this.searchKeyword = null;
      this.selectTag = null;
      this.isCommercial = true;
      this.isTextbook = false;
      this.refreshList();
    },
    async changePage(page) {
      this.paginateData.page = page;
      await this.refreshList();
    },
    async refreshList() {
      this.isLoading = true;
      const selectTabIndex = this.selectTabIndex;
      this.workbooks = [];

      let { items, page, page_size, total_pages } = await WorkbookService.getWorkbookList({
        'page_size': this.paginateData.size,
        'page': this.paginateData.page,
        'is_only_my_academy': selectTabIndex == 0,
        'school_type': this.selectSchoolType != '전체' ? this.selectSchoolType : null,
        'name': this.searchKeyword,
        'tag': this.selectTag != '전체' ? this.selectTag : null,
        'is_commercial': this.selectTabIndex == 0 ? null : this.isCommercial,
        'is_textbook': this.selectTabIndex == 0 ? null : this.isTextbook,
      });

      this.isLoading = false;
      this.workbooks = items;
      this.paginateData.size = page_size;
      this.paginateData.page = page;
      this.paginateData.totalPage = total_pages;
    },
    onClickDownload(workbook) {
      this.downloadWorkbook = workbook;
    },
  },
  computed: {
    checkedWorkbooks() {
      return this.workbooks.filter(i => i.isChecked)
    },
    filterData() {
      return {
        title: '교재 필터',
        list: this.selectTabIndex == 0
            ? [
              {type: 'select', addClass: 'w-100px', name: '학년', options: this.schoolTypes.map((i) => {
                  return {
                    'value': i,
                    'name': i,
                    'isSelected': this.selectSchoolType == i,
                  }
                }), onChange: (value) => this.selectSchoolType = value},
              {type: 'select', addClass: 'w-130px', name: '교재 태그', options: this.tags.map((i) => {
                  return {
                    'value': i,
                    'name': i,
                    'isSelected': this.selectTag == i,
                  }
                }), onChange: (value) => this.selectTag = value},
            ]
            : [
              {type: 'checkbox', addClass: '', name: '교과서', value: this.isTextbook, onChange: (isChecked) => {this.isTextbook = isChecked;this.isCommercial = !isChecked}},
              {type: 'checkbox', addClass: '', name: '시중교재', value: this.isCommercial, onChange: (isChecked) => {this.isCommercial = isChecked;this.isTextbook = !isChecked}},
              {type: 'select', addClass: 'w-100px', name: '학년', options: this.schoolTypes.map((i) => {
                  return {
                    'value': i,
                    'name': i,
                  }
                }), onChange: (value) => this.selectSchoolType = value},
            ],
        createBtn: {
          isVisible: this.selectTabIndex == 0,
          name: '교재 만들기',
          onCreate: () => {
            this.$router.push({ name: utils.isV2Url(this) ? 'TeacherWorkbookEdit' : 'WorkbookEdit' })
          },
        },
        searchInput: {
          placeholder: '교재명 검색',
          value: this.searchKeyword,
          onChange: (value) => this.searchKeyword = value,
        },
      }
    },
    listData() {
      if (this.selectTabIndex == 0) {
        return {
          heads: [
            {title: '선택', type: 'checkbox', addClass: 'w-75px'},
            {title: '학년', type: 'string', addClass: 'w-100px'},
            {title: '교재명', type: 'string', addClass: 'flex-1'},
            {title: '생성일', type: 'string', addClass: 'w-80px'},
            {title: '미리보기', type: 'prev-btn', addClass: 'w-80px'},
            {title: '출제', type: 'submit-btn', addClass: 'w-110px'},
            {title: '', type: 'etc-btn', addClass: 'w-50px'},
          ],
          trList: this.workbooks.map(wb => {
            const result = [
              {value: `${wb.id}`, isChecked: wb.isChecked, onChange: () => this. onChangeItem(wb)},
              {value: wb.grade},
              {value: wb.name},
              {value: wb.create_dt},
              {value: '', onClickPrevBtn: () => utils.previewLink(this, 'preview', utils.PdfType.workbook, wb.id)},
              {value: '', onClickSubmitBtn: () => this.takeTestWorkbook = wb},
              {
                value: '',
                customList: [
                  {title: '수정', onClick: () => this.redirectEdit(wb.id)},
                  {title: '복제', onClick: () => this.redirectCopy(wb.id)},
                  {title: '다운로드', onClick: () => this.onClickDownload(wb)},
                  {title: '삭제', onClick: () => this.onDeleteWorkbookTarget(wb)},
                ],
              },
            ]

            result.forEach(i => {
              i.onClick = () => this.onChangeItem(wb)
            })

            return result
          })
        }
      } else {
        return {
          heads: [
            {title: '학년', type: 'string', addClass: 'w-100px'},
            {title: '교재명', type: 'string', addClass: 'flex-1'},
            {title: '판수', type: 'string', addClass: 'w-50px'},
            {title: '출판사', type: 'string', addClass: 'w-120px'},
            {title: '출제', type: 'submit-btn', addClass: 'w-110px'},
          ],
          trList: this.workbooks.map(wb => [
            {value: wb.grade},
            {value: wb.name},
            {value: wb.noc},
            {value: wb.publisher},
            {value: '', onClickSubmitBtn: () => this.takeTestWorkbook = wb},
          ]),
        }
      }
    },
  },
}
</script>
