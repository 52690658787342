import api from "./api"
import {FolderResultVo} from "@/models/folder_result_vo";
import {StudentWorkFolderVo} from "@/models/student_work_folder_vo";
import {StudentWorkFolderProblemVo} from "@/models/student_work_folder_problem_vo";

class StudentWorkService {
  createStudentWork(payload) {
    return api.post('/view_set/student_work/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
  
  getStudentWorks(params) {
    return api.get(`/view_set/student_work/`, { params })
  }

  getStudentWorksByHistory(student_id, params) {
    return api.get(`/problem/student_problem/${student_id}/`, { params })
  }
  
  getStudentWork(id, params) {
    return api.get(`/view_set/student_work/${id}/`, { params })
  }
  
  getStudentWorkProblems(params) {
    return api.get(`/view_set/student_work_problem/`, { params })
  }

  updateStudentWorkProblem(studentWorkProblemId, payload) {
    return api.patch(`/view_set/student_work_problem/${studentWorkProblemId}/`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  getPreviewData(studentWorkId) {
    return api.get(`/student_work/preview/data/${studentWorkId}/`, {});
  }

  /**
   * @param {number} studentWorkProblemId
   * @param {string} answer
   * @param {boolean} isPass
   */
  submitAnswer(
      studentWorkProblemId,
      answer,
      isPass,
  ) {
    const payload = {
      user_answer: answer,
      is_pass: isPass,
    };
    return api.post(`/student_work/submit/answer/${studentWorkProblemId}/`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  getResult(studentWorkId) {
    return api.get(`/student_work/result/${studentWorkId}`, {});
  }

  getFolders() {
    try {
      return api.get(`/student_work/folders`, {});
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  /**
   * @param {number} folderId
   * @param {string} folderName
   * @return {Promise<boolean>}
   */
  async editStudentWorkFolder(
    folderId,
    folderName,
  ) {
    const payload = {
      name: folderName,
    };

    try {
      await api.post(`/student_work/folder/edit/${folderId}`, payload, {
        headers: {"Content-Type": "multipart/form-data"},
      });
      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  /**
   * @param {string} folderName
   * @return {Promise<boolean>}
   */
  async createStudentWorkFolder(
    folderName,
  ) {
    const payload = {
      name: folderName,
    };

    try {
      await api.post(`/student_work/folder`, payload, {
        headers: {"Content-Type": "multipart/form-data"},
      });
      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  /**
   * @param {number} folderId
   * @return {Promise<boolean>}
   */
  async deleteFolder(
    folderId,
  ) {
    try {
      await api.delete(`/student_work/folder_delete/${folderId}`, {}, {
        headers: {"Content-Type": "multipart/form-data"},
      });
      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  /**
   * @param {number} folderId
   * @return {Promise<boolean>}
   */
  async copyStudentWorkFolder(
    folderId,
  ) {
    try {
      await api.post(`/student_work/folder/copy/${folderId}`, {}, {
        headers: {"Content-Type": "multipart/form-data"},
      });
      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  /**
   * @param {number} folderId
   * @return {Promise<boolean>}
   */
  async solveAgainStudentWorkFolder(
    folderId,
  ) {
    try {
      await api.post(`/student_work/folder/solve_again/${folderId}`, {}, {
        headers: {"Content-Type": "multipart/form-data"},
      });
      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  /**
   * @param {number} folderId
   * @return {Promise<FolderResultVo|null>}
   */
  async getFolderResult(
    folderId,
  ) {
    try {
      const res = await api.get(`/student_work/folder/result/${folderId}`, {});
      return FolderResultVo.fromJson(res);
    } catch (e) {
      console.log(e);
    }

    return null;
  }

  /**
   * @param {number} folderProblemId
   * @return {Promise<boolean>}
   */
  async deleteFolderProblem(
      folderProblemId,
  ) {
    try {
      await api.delete(`/student_work/folder/problem_delete/${folderProblemId}`, {}, {
        headers: {"Content-Type": "multipart/form-data"},
      });
      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  /**
   * @param {number|null} studentWorkProblemId
   * @return {Promise<StudentWorkFolderProblemVo[]|null>}
   */
  async getStudentWorkFolderProblems(
      studentWorkProblemId,
  ) {
    const queries = [];
    if (studentWorkProblemId !== null) {
      queries.push(`student_work_problem_id=${studentWorkProblemId}`);
    }

    try {
      const res = await api.get(`/student_work/folder/problems/?${queries.join('&')}`, {});
      return res.map((e) => StudentWorkFolderProblemVo.fromJson(e));
    } catch (e) {
      console.log(e);
    }

    return null;
  }

  /**
   * @param {number[]} folderIds
   * @param {number} studentWorkProblemId
   * @return {Promise<boolean>}
   */
  async changeFolderProblem(
      folderIds,
      studentWorkProblemId,
  ) {
    const formData = new FormData();
    formData.append('student_work_problem_id', studentWorkProblemId);
    for (let index = 0; index < folderIds.length; index++) {
      formData.append('folder_ids', folderIds[index]);
    }

    try {
      await api.post(`/student_work/folder/problem/change/`, formData, {
        headers: {"Content-Type": "multipart/form-data"},
      });
      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }
}

export default new StudentWorkService()
