import {getWeekDateRangeByDt} from "@/utils/date";

const itemKey = 'learningHistoryDate';
const item = JSON.parse(sessionStorage.getItem(itemKey));

const initDate = new Date();
const initialState = item ?? {
  'nowTime': initDate.getTime(),
  'selectWeekTimeRange': getWeekDateRangeByDt(initDate),
  'isChangeSelectWeek': false,
  'thisWeekTimeRange': getWeekDateRangeByDt(initDate),
  'last1WeekTimeRange': getWeekDateRangeByDt(initDate, 7),
  'last2WeekTimeRange': getWeekDateRangeByDt(initDate, 7 * 2),
  'last3WeekTimeRange': getWeekDateRangeByDt(initDate, 7 * 3),
};

export const learningHistoryDate = {
  namespaced: true,
  state: initialState,
  actions: {
    initLearningHistoryDate({ commit }) {
      commit('initData');
    },
    setSelectWeekTimeRangeStartDt({ commit }, params) {
      commit('setSelectWeekTimeRangeStartDt', params);
    },
    setSelectWeekTimeRangeEndDt({ commit }, params) {
      commit('setSelectWeekTimeRangeEndDt', params);
    },
  },
  mutations: {
    initData(state, params) {
      const nowDt = new Date();
      state.nowTime = nowDt.getTime();
      if (!state.isChangeSelectWeek) {
        state.selectWeekTimeRange = getWeekDateRangeByDt(nowDt);
      }
      state.thisWeekTimeRange = getWeekDateRangeByDt(nowDt);
      state.last1WeekTimeRange = getWeekDateRangeByDt(nowDt, 7);
      state.last2WeekTimeRange = getWeekDateRangeByDt(nowDt, 7 * 2);
      state.last3WeekTimeRange = getWeekDateRangeByDt(nowDt, 7 * 3);

      sessionStorage.setItem(itemKey, JSON.stringify(state));
    },
    setSelectWeekTimeRangeStartDt(state, params) {
      state.selectWeekTimeRange.startTime = params.getTime();
      sessionStorage.setItem(itemKey, JSON.stringify(state));
    },
    setSelectWeekTimeRangeEndDt(state, params) {
      state.selectWeekTimeRange.endTime = params.getTime();
      sessionStorage.setItem(itemKey, JSON.stringify(state));
    },
  },
  getters: {
    getLearningHistoryNowDt: (state) => new Date(state.nowTime),
    getSelectWeekTimeRangeStartDt: (state) => new Date(state.selectWeekTimeRange.startTime),
    getSelectWeekTimeRangeEndDt: (state) => new Date(state.selectWeekTimeRange.endTime),
    getThisWeekTimeRangeStartDt: (state) => new Date(state.thisWeekTimeRange.startTime),
    getThisWeekTimeRangeEndDt: (state) => new Date(state.thisWeekTimeRange.endTime),
    getLast1WeekTimeRangeStartDt: (state) => new Date(state.last1WeekTimeRange.startTime),
    getLast1WeekTimeRangeEndDt: (state) => new Date(state.last1WeekTimeRange.endTime),
    getLast2WeekTimeRangeStartDt: (state) => new Date(state.last2WeekTimeRange.startTime),
    getLast2WeekTimeRangeEndDt: (state) => new Date(state.last2WeekTimeRange.endTime),
    getLast3WeekTimeRangeStartDt: (state) => new Date(state.last3WeekTimeRange.startTime),
    getLast3WeekTimeRangeEndDt: (state) => new Date(state.last3WeekTimeRange.endTime),
  },
};
