<template>
  <V2Wrapper>
    <MyProblemEdit/>
  </V2Wrapper>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import MyProblemEdit from "@/pages/mypage/teacher/class-pre/MyProblemEdit.vue";

export default {
  name: 'TeacherWorkbook',
  components: {
    V2Wrapper,
    MyProblemEdit,
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {},
  computed: {},
}
</script>
