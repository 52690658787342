<template>
  <div class="self-learning-mode-result-wrap">
    <V2Wrapper>
      <SelfLearningModeResult/>
    </V2Wrapper>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import SelfLearningModeResult from "@/components/SelfLearningModeResult.vue";

export default {
  name: 'TeacherSelfLearningModeResult',
  components: {
    V2Wrapper,
    SelfLearningModeResult,
  },
}
</script>
