export class ConceptVidItem {
    /** @type {number} 숙제 ID */
    studentWorkId;
    /** @type {number} 소단원 문제 패턴 ID */
    patternId;
    /** @type {string|null} */
    conceptVidUrl;

    constructor(
        studentWorkId,
        patternId,
        conceptVidUrl = null,
    ) {
        this.studentWorkId = studentWorkId;
        this.patternId = patternId;
        this.conceptVidUrl = conceptVidUrl;
    }

    static fromJson = (json) => new ConceptVidItem(
        json.studentWorkId,
        json.patternId,
        json.conceptVidUrl,
    );

    /**
     * @param {ConceptVidItem} item
     * @return {boolean}
     */
    isSame(item) {
        return this.studentWorkId == item.studentWorkId
            && this.patternId == item.patternId;
    }

    /**
     * @param {ConceptVidItem[]} conceptVidUrls
     * @return {boolean}
     */
    isExist(conceptVidUrls) {
        return conceptVidUrls.findIndex(e => this.isSame(e)) >= 0;
    }
}
