export class StudentWorkFolderProblemVo {
    /** @type {number} */
    id;
    /** @type {number} */
    folderId;
    /** @type {number} */
    problemId;
    /** @type {Date} */
    createDt;
    /** @type {Date} */
    updateDt;

    constructor(
        id,
        folderId,
        problemId,
        createDt,
        updateDt,
    ) {
        this.id = id;
        this.folderId = folderId;
        this.problemId = problemId;
        this.createDt = createDt;
        this.updateDt = updateDt;
    }

    static fromJson = (json) => new StudentWorkFolderProblemVo(
        json.id,
        json.folder,
        json.problem,
        new Date(json.create_dt),
        new Date(json.update_dt),
    );
}
