export class YoutubeEmbedPopupItem {
    /** @type {string} */
    youtubeVideoId;
    /** @type {number} */
    startSeconds;

    constructor(
        youtubeVideoId,
        startSeconds,
    ) {
        this.youtubeVideoId = youtubeVideoId;
        this.startSeconds = startSeconds ?? 0;
    }
}
