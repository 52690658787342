export const ProblemTypeExt = {
    multiple: '객관식',
    sat: '수능형',
    shortAnswer: '단답형',
    ox: 'OX형',
    experimental: '실험형',
}

export class ProblemTypeExtUtils {
    static isNumberAnswerType(problemTypeExt) {
        return [
            ProblemTypeExt.multiple,
            ProblemTypeExt.sat,
            ProblemTypeExt.experimental,
        ].includes(problemTypeExt);
    }
}
