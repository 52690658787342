<template>
  <div class="unit-learning-map-wrap">
    <div class="ulm-abs-wrap">
      <div class="m-inner-wrap"
           ref="mInnerWrap"
           :style="[{'transform': `scale(${this.scaleSize})`}]"
      >
        <div class="m-item-wrap">
          <div class="item-box box-parent">
            <p>중력과 역학적 시스템</p>
          </div>
          <div class="i-child-list-wrap">
            <div class="item-box box-child c-type-0 cursor-pointer"
                 @click="() => this.$router.push({ name: 'TeacherSelfLearningMode', query: {'checkedId': '525'} })"
            >
              <div class="ic-header-wrap">
                <p>중등 2학년 6단원</p>
                <div>부족</div>
              </div>
              <p>운동 에너지와 위치 에너지</p>
            </div>
            <div class="item-box box-child c-type-1">
              <div class="ic-header-wrap">
                <p>중등 1학년 2단원</p>
                <div>통과</div>
              </div>
              <p>지구가 당기는힘</p>
            </div>
          </div>
        </div>
      </div>
      <div class="m-controller-wrap">
        <button @click="() => this.scaleSize += this.controllingScaleSize">
          <img src="@/assets/images/add.png" alt="">
        </button>
        <button @click="() => this.scaleSize -= this.controllingScaleSize">
          <img src="@/assets/images/remove.png" alt="">
        </button>
        <button @click="() => this.scaleSize = 1">
          <img src="@/assets/images/fill.png" alt="">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnitLearningMap',
  watch: {
    scaleSize() {
      this.calcPosition();
    },
  },
  data() {
    return {
      scaleSize: 1,
      controllingScaleSize: .2,
    };
  },
  mounted() {
    window.addEventListener("resize", this.resizedEvent);
    this.calcPosition();
  },
  unmounted() {
    window.removeEventListener("resize", this.resizedEvent);
  },
  methods: {
    resizedEvent() {
      this.calcPosition();
    },
    calcPosition() {
      this.removeAllLines();

      const mInnerWrap = this.$refs.mInnerWrap;
      const mItemWraps = mInnerWrap.querySelectorAll('.m-item-wrap');

      mItemWraps.forEach(mItemWrap => {
        this.drawChildLines(mItemWrap);
      });
    },
    drawChildLines(mItemWrap) {
      const boxParent = mItemWrap.querySelector('.box-parent');
      const boxChildList = mItemWrap.querySelectorAll('.box-child');

      const lineGap = 3;
      const maxWidth = mItemWrap.clientWidth;
      const pHeight = boxParent.clientHeight;

      boxChildList.forEach(boxChild => {
        const boxWidth = boxChild.clientWidth;
        this.drawLine(
            mItemWrap,
            maxWidth / 2,
            pHeight + lineGap,
            boxChild.offsetLeft + (boxWidth / 2),
            boxChild.offsetTop - lineGap,
        );
      });
    },
    removeAllLines() {
      const mInnerWrap = this.$refs.mInnerWrap;
      let iLineWrap = mInnerWrap.querySelector('.i-line-wrap');
      if (iLineWrap === null) return;

      iLineWrap.remove();
    },
    drawLine(relElem, x, y, x2, y2) {
      const canvasRadio = this.scaleSize + 1;
      const lineWidth = 1;

      let iLineWrap = relElem.querySelector('.i-line-wrap');
      if (iLineWrap === null) {
        iLineWrap = document.createElement('div');
        iLineWrap.classList.add('i-line-wrap');
        relElem.append(iLineWrap);
      }

      const minX = Math.min(x, x2);
      const maxX = Math.max(x, x2);
      const minY = Math.min(y, y2);
      const maxY = Math.max(y, y2);

      const lineBoxWidth = maxX - minX;
      const lineBoxHeight = maxY - minY;

      const lineWrap = document.createElement('div');
      lineWrap.style.position = 'absolute';
      lineWrap.style.top = `${minY}px`;
      lineWrap.style.left = `${minX}px`;
      lineWrap.style.width = `${lineBoxWidth}px`;
      lineWrap.style.height = `${lineBoxHeight}px`;
      iLineWrap.append(lineWrap);

      const lineInnerWrap = document.createElement('div');
      lineInnerWrap.style.position = 'relative';
      lineInnerWrap.style.width = `${lineBoxWidth}px`;
      lineInnerWrap.style.height = `${lineBoxHeight}px`;
      lineWrap.append(lineInnerWrap);

      const lineCanvas = document.createElement('canvas');
      lineCanvas.style.position = 'absolute';
      lineCanvas.style.top = '0px';
      lineCanvas.style.left = '0px';
      lineCanvas.width = lineBoxWidth * canvasRadio;
      lineCanvas.height = lineBoxHeight * canvasRadio;
      lineCanvas.style.width = `${lineBoxWidth}px`;
      lineCanvas.style.height = `${lineBoxHeight}px`;
      lineInnerWrap.append(lineCanvas);

      const lineCanvasWidth = lineCanvas.width;
      const lineCanvasHeight = lineCanvas.height;
      const lineCtx = lineCanvas.getContext('2d');

      lineCtx.beginPath();
      lineCtx.moveTo(lineCanvasWidth, 0);
      lineCtx.bezierCurveTo(
          lineCanvasWidth,
          lineCanvasHeight,
          0,
          0,
          0,
          lineCanvasHeight,
      );
      lineCtx.lineWidth = lineWidth * canvasRadio;
      lineCtx.strokeStyle = '#B3B3B3';
      lineCtx.stroke();

      if (x2 > x) {
        lineInnerWrap.style.transform = 'scale(-1, 1)';
      }

      const arrowSize = 4;
      const arrowCanvas = document.createElement('canvas');
      arrowCanvas.style.position = 'absolute';
      arrowCanvas.style.top = `${lineBoxHeight - arrowSize}px`;
      arrowCanvas.style.left = `-${arrowSize}px`;
      arrowCanvas.width = arrowSize * 2 * canvasRadio;
      arrowCanvas.height = arrowSize * canvasRadio;
      arrowCanvas.style.width = `${arrowSize * 2}px`;
      arrowCanvas.style.height = `${arrowSize}px`;
      lineInnerWrap.append(arrowCanvas);

      const arrowCanvasWidth = arrowCanvas.width;
      const arrowCanvasHeight = arrowCanvas.height;

      const arrowCtx = arrowCanvas.getContext('2d');
      arrowCtx.beginPath();
      arrowCtx.moveTo(arrowCanvasWidth / 2, arrowCanvasHeight);
      arrowCtx.lineTo(0, 0);
      arrowCtx.lineWidth = lineWidth * canvasRadio;
      arrowCtx.strokeStyle = '#B3B3B3';
      arrowCtx.stroke();

      arrowCtx.beginPath();
      arrowCtx.moveTo(arrowCanvasWidth / 2, arrowCanvasHeight);
      arrowCtx.lineTo(arrowCanvasWidth, 0);
      arrowCtx.stroke();
    },
  },
}
</script>
