import {getServerMediaUrl} from "@/utils/parse";
import {DividerExtUtils} from "@/extensions/divider_ext";

export class StudentWorkResultProblemVo {
    /** @type {number} */
    id;
    /** @type {number} */
    problemId;
    /** @type {string} */
    problemAnswer;
    /** @type {string} */
    problemImg;
    /** @type {string} */
    problemSolutionImg;
    /** @type {number} */
    order;
    /** @type {string|null} */
    answer;
    /** @type {boolean|null} */
    isCurrentAnswer;
    /** @type {string|null} */
    teacherSolvingImg;
    /** @type {string|null} */
    solvingImg;
    /** @type {number} */
    problemScore;
    /** @type {boolean} */
    isShowSolution;

    constructor(
        id,
        problemId,
        problemAnswer,
        problemImg,
        problemSolutionImg,
        order,
        answer,
        isCurrentAnswer,
        teacherSolvingImg,
        solvingImg,
        problemScore,
        isShowSolution,
    ) {
        this.id = id;
        this.problemId = problemId;
        this.problemAnswer = problemAnswer;
        this.problemImg = problemImg;
        this.problemSolutionImg = problemSolutionImg;
        this.order = order;
        this.answer = answer;
        this.isCurrentAnswer = isCurrentAnswer;
        this.teacherSolvingImg = teacherSolvingImg;
        this.solvingImg = solvingImg;
        this.problemScore = problemScore;
        this.isShowSolution = isShowSolution;
    }

    static fromJson = (json) => new StudentWorkResultProblemVo(
        json.id,
        json.problem_id,
        json.problem_answer,
        getServerMediaUrl(json.problem_img),
        getServerMediaUrl(json.problem_solution_img),
        json.order,
        json.answer,
        json.is_current_answer,
        json.teacher_solving_img ? getServerMediaUrl(json.teacher_solving_img) : null,
        json.solving_img ? getServerMediaUrl(json.solving_img) : null,
        json.problem_score,
        false,
    );

    getNumber = () => this.order + 1;
    getCleanProblemAnswer = () => DividerExtUtils.getCleanAnswer(this.problemAnswer);
    getCleanAnswer() {
        const answer = this.answer;
        if (answer == null) return '[ 풀이 중 ]';
        if (answer == '') return '[ 넘김 ]';

        return DividerExtUtils.getCleanAnswer(this.answer);
    }
}
