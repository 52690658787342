import AuthService from '../services/auth.service';
import app from '../main'
import router from '../router';
import store from "@/store/index";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    changeUserAction({ commit }, user) {
      commit('changeUser', user)
    },
    async signIn({ commit }, params) {
      const res = await AuthService.signIn(params).then(
        user => {
          if (user) {
            commit('loginSuccess', user);

            app.config.globalProperties.$toast.success('로그인 되었습니다')
            const {redirect} = params
            router.push(redirect ? redirect : { name: 'home' })

            return Promise.resolve(user);
          } else {
            commit('loginFailure');
            return Promise.reject(user);
          }
        },
        error => {
          const status = error.response?.status
          const data = error.response?.data
          if (status && data && status == 400) {
            console.log(error.response);
            if (data.non_field_errors == "주어진 자격 증명으로 로그인이 불가능합니다.") {
              app.config.globalProperties.$toast.error('이메일 혹은 비밀번호를 확인해주세요')
            } else if (data.message) {
              app.config.globalProperties.$toast.error(data.message)
            } else if (data.length == 2 && data[0] == 'message') {
              app.config.globalProperties.$toast.error(data[1])
            } else if (data.length == 2 && data[1] == 'message') {
              app.config.globalProperties.$toast.error(data[0])
            }
          } else if (status && data && status == 404) {
            const {message} = data
            if (message) {
              app.config.globalProperties.$toast.success(`${message}\n회원가입을 진행해주세요`)
              router.push({ name: 'signup', query: params })
            }
          }
          commit('loginFailure');
          return Promise.reject(error);
        }
      );

      return res;
    },
    signout({ commit }, isToast=true) {
      AuthService.signout();
      if (isToast) {
        app.config.globalProperties.$toast.success('로그아웃 되었습니다')
      }
      commit('signout');
    },
    signup({ commit }, user) {
      return AuthService.signup(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    changeIsShowQr({ commit }, isShowQr) {
      commit('changeIsShowQr', isShowQr);
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    changeUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    signout(state) {
      state.status.loggedIn = false;
      state.user = null;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    changeIsShowQr(state, isShowQr) {
      state.user.is_show_qr = isShowQr;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
  },
  getters: {
    getUser: (state) => {
      const defaultUser = {};

      return Object.assign({}, defaultUser, state.user ?? {});
    },
    isLogin: (state) => {
      return state.status.loggedIn;
    },
    isUseFree: (state) => {
      return state.user?.is_use_free
    },
    isFree: (state) => {
      let freeExpireDt = state.user?.free_expire_dt
      if (freeExpireDt) {
        const nowDt = new Date()
        freeExpireDt = new Date(freeExpireDt)
        return nowDt < freeExpireDt
      }

      return false
    },
    isStudent: (state) => {
      return state.user?.is_student;
    },
    isAcademyStudent: (state) => {
      const isStudent = state.user?.is_student
      const isAcademyStudent = state.user?.academy_user?.is_student

      return isStudent && isAcademyStudent
    },
    isParents: (state) => {
      return state.user?.is_parents;
    },
    isAcademyParents: (state) => {
      const isParents = state.user?.is_parents
      const isAcademyParents = state.user?.academy_user?.is_parents

      return isParents && isAcademyParents
    },
    getStudentAcademyUserId: (state) => {
      const isStudent = state.user?.is_student;
      const isAcademyStudent = state.user?.academy_user?.is_student;
      if (isStudent && isAcademyStudent) {
        return state.user?.academy_user?.id;
      } else {
        const isParents = state.user?.is_parents
        const isAcademyParents = state.user?.academy_user?.is_parents

        if (isParents && isAcademyParents) {
          return state.user?.connect_student_user?.academy_user?.id;
        }
      }

      return null;
    },
    getTeacherAcademyUserId: (state) => {
      const isAcademyTeacher = state.user?.academy_user?.is_teacher;
      if (isAcademyTeacher) {
        return state.user?.academy_user?.id;
      }
      return null;
    },
    getLastExpireDt: (state) => {
      const freeExpireDtStr = state.user?.free_expire_dt;
      const freeExpireDt = freeExpireDtStr ? new Date(freeExpireDtStr) : null;
      const useExpireDtStr = state.user?.use_expire_dt;
      const useExpireDt = useExpireDtStr ? new Date(useExpireDtStr) : null;
      const admExpireDtStr = state.user?.adm_expire_dt;
      const admExpireDt = admExpireDtStr ? new Date(admExpireDtStr) : null;

      let lastExpireDt;
      if (freeExpireDt) {
        lastExpireDt = freeExpireDt;
      }
      if (useExpireDt) {
        if (lastExpireDt) {
          if (useExpireDt > lastExpireDt) {
            lastExpireDt = useExpireDt;
          }
        } else {
          lastExpireDt = useExpireDt;
        }
      }
      if (admExpireDt) {
        if (lastExpireDt) {
          if (admExpireDt > lastExpireDt) {
            lastExpireDt = admExpireDt;
          }
        } else {
          lastExpireDt = admExpireDt;
        }
      }

      return lastExpireDt;
    },
    isTeacher: (state) => {
      return state.user?.academy_user?.is_teacher ?? false;
    },
    isPaidTeacher: (state) => {
      const isTeacher = store.getters["auth/isTeacher"];
      if (isTeacher) {
        const lastExpireDt = store.getters["auth/getLastExpireDt"];
        const nowDt = new Date();

        return lastExpireDt && lastExpireDt >= nowDt;
      }

      return false;
    },
    isShowQr: (state) => {
      return state.user?.is_show_qr ?? true;
    },
  },
};
