<template>
  <div class="self-learning-mode-wrap">
    <V2Wrapper>
      <div class="slmw-inner">
        <div class="v2-base-container">
          <div class="sec-left-wrap">
            <div class="sec-tab-wrap">
              <p>범위 선택</p>
              <div class="st-divider"></div>
              <div class="select-wrap">
                <select>
                  <option selected>초등학교</option>
                </select>
                <img src="@/assets/images/arrow-right-7.png" alt="">
                <select>
                  <option selected>1-1</option>
                </select>
              </div>
            </div>
            <div class="sec-list-wrap">
              <V2TreeList
                  :nodes="this.treeNodes"
              />
            </div>
          </div>
          <div class="sec-right-wrap">
            <div class="sec-tab-list">
              <template v-for="(tabName, index) in this.tabs" :key="index">
                <div :class="['tl-item', {'active': index === this.selectTabIndex}]"
                     @click="() => this.selectTabIndex = index"
                >
                  <p>{{ tabName }}</p>
                  <div></div>
                </div>
              </template>
            </div>
            <div class="sec-body-wrap">
              <template v-for="(unitItem, index) in this.unitItems" :key="index">
                <div class="sb-item">
                  <h5>{{ unitItem.title }}</h5>
                  <div class="sbi-list-wrap">
                    <template v-for="(item, index) in unitItem.items" :key="index">
                      <button :class="['il-item', {'active': item.isSelect}]"
                              @click="() => item.isSelect = !item.isSelect"
                      >
                        <span>{{ item.name }}</span>
                      </button>
                    </template>
                  </div>
                </div>
              </template>
            </div>
            <div class="sec-button-wrap">
              <button>
                <span>작성 완료</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </V2Wrapper>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import V2TreeList from "@/components/V2TreeList.vue";

export default {
  name: 'StudentSelfLearningMode',
  components: {V2TreeList, V2Wrapper},
  data() {
    return {
      tabs: [
        '진단평가',
        '단원평가',
      ],
      selectTabIndex: 0,
      unitItems: [
        {
          title: '학습 프로세스',
          items: [
            {name: '개념영상', isSelect: false},
            {name: '심화문제', isSelect: false},
            {name: '취약개념', isSelect: false},
            {name: '취약문제', isSelect: false},
            {name: '실험콘텐츠', isSelect: false},
            {name: '실감콘텐츠', isSelect: true},
          ],
        },
      ],
      treeNodes: {
        id1: {
          text: '초 1-1',
          children: ['id1_1', 'id1_2', 'id1_3'],
        },
        id1_1: {
          text: '9까지의 수',
          children: ['id1_1_1', 'id1_1_2', 'id1_1_3'],
        },
        id1_1_1: {
          text: '1부터 5까지의 수 알아보기',
        },
        id1_1_2: {
          text: '1부터 5까지의 수 알아보기',
        },
        id1_1_3: {
          text: '1부터 5까지의 수 알아보기',
        },
        id1_2: {
          text: '6부터 9까지의 수',
        },
        id1_3: {
          text: '몇째',
        },
        id2: {
          text: '여러 가지 모양',
        },
        id3: {
          text: '덧셈과 뺄셈',
        },
        id4: {
          text: '덧셈과 뺄셈',
        },
        id5: {
          text: '덧셈과 뺄셈',
        },
        id6: {
          text: '덧셈과 뺄셈',
        },
        id7: {
          text: '덧셈과 뺄셈',
        },
        id8: {
          text: '덧셈과 뺄셈',
        },
        id9: {
          text: '덧셈과 뺄셈',
        },
        id10: {
          text: '덧셈과 뺄셈',
        },
        id11: {
          text: '덧셈과 뺄셈',
        },
      },
    };
  },
}
</script>
