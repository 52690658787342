<template>
  <div class="mypage-teacher-wrap">
    <MypageList
        :selectTabIndex="this.selectTabIndex"
        :tabs="this.tabs"
        :onTabClick="onChangeTab"
        :filterData="this.filterData"
        :listData="this.listData"
        :paginateData="this.paginateData"
        :onPageChange="this.changePage"
        :isLoading="this.isLoading"
    >
      <template v-slot:tabBody>
        <div :class="['select-list', {'open': checkedWorksheets.length > 0}]">
          <h1>학습지 {{ checkedWorksheets.length }}개 선택됨</h1>

          <div class="btn-list">
            <template v-if="selectTabIndex == 0">
              <div class="btn-item" @click="onCreateFavoriteWorksheet">
                <svg xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="none" stroke="none"><path fill="silver" d="M2515 4801 c-11 -5 -30 -19 -41 -32 -12 -13 -169 -323 -349 -689 -180 -366 -331 -669 -336 -674 -7 -8 -1445 -224 -1496 -226 -7 0 -28 -14 -47 -30 -40 -35 -53 -84 -34 -129 7 -17 152 -166 323 -332 171 -167 415 -406 543 -532 l233 -229 -126 -733 c-69 -402 -125 -750 -125 -771 0 -46 20 -78 60 -99 63 -33 -14 -68 918 422 l522 275 674 -356 c753 -398 727 -387 792 -322 28 28 34 42 34 75 0 23 -56 370 -125 771 -69 401 -125 731 -125 733 0 3 243 242 541 533 297 290 547 540 555 555 8 16 14 40 14 55 0 34 -33 88 -63 101 -13 6 -358 59 -768 119 -409 59 -749 112 -756 118 -6 6 -159 310 -338 676 -181 367 -339 678 -355 693 -29 31 -89 44 -125 28z"/></g></svg>
                <span>즐겨찾기</span>
              </div>
            </template>
            <template v-else>
              <div class="btn-item" @click="onDeleteFavoriteWorksheet">
                <svg xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="none" stroke="none"><path fill="silver" d="M2515 4801 c-11 -5 -30 -19 -41 -32 -12 -13 -169 -323 -349 -689 -180 -366 -331 -669 -336 -674 -7 -8 -1445 -224 -1496 -226 -7 0 -28 -14 -47 -30 -40 -35 -53 -84 -34 -129 7 -17 152 -166 323 -332 171 -167 415 -406 543 -532 l233 -229 -126 -733 c-69 -402 -125 -750 -125 -771 0 -46 20 -78 60 -99 63 -33 -14 -68 918 422 l522 275 674 -356 c753 -398 727 -387 792 -322 28 28 34 42 34 75 0 23 -56 370 -125 771 -69 401 -125 731 -125 733 0 3 243 242 541 533 297 290 547 540 555 555 8 16 14 40 14 55 0 34 -33 88 -63 101 -13 6 -358 59 -768 119 -409 59 -749 112 -756 118 -6 6 -159 310 -338 676 -181 367 -339 678 -355 693 -29 31 -89 44 -125 28z"/></g></svg>
                <span>즐겨찾기 해제</span>
              </div>
            </template>

            <div class="btn-item" @click="() => {isOpenDeletePopup = true}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="delete" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 16px; color: white;"><path fill="silver" d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zm10.833-12.5h-2.916l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333z"></path></svg>
              <span>선택 삭제</span>
            </div>
            <div class="close-btn" @click="onClickCloseBtn">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(159, 159, 159);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
            </div>
          </div>
        </div>
      </template>
    </MypageList>
  </div>

  <Popup
      :isTitleExclamationMark="true"
      :popupTitle="'주의'"
      :isVisible="isOpenDeletePopup"
      :onClose="() => {isOpenDeletePopup = false}"
      :onConfirm="onDeleteWorksheets"
      :onCancel="() => {}"
  >
    <template v-slot:bodyContents>
      <div>학습지를 삭제하면 학생에게 출제한 내역이 모두 사라지고<br>다시 복구가 불가능합니다.<br>정말 삭제하시겠습니까?</div>
    </template>
  </Popup>

  <TakeTestPopup
      :isVisible="!!this.takeTestWorksheet"
      :worksheetId="this.takeTestWorksheet?.id"
      :onClose="() => this.takeTestWorksheet = null"
      :subTitle="this.takeTestWorksheet?.name ?? ''"
  />
  <DownloadPopup
      :is-visible="!!this.downloadWorksheet"
      :worksheet="this.downloadWorksheet"
      :on-close="() => this.downloadWorksheet = null"
  />
</template>

<script>
import MypageList from '../../../../components/MypageList.vue'
import WorksheetService from '@/services/worksheet.service'
import models from '../../../../models'
import utils from '../../../../utils'
import Popup from '@/components/Popup.vue'
import TakeTestPopup from '@/components/TakeTestPopup.vue'
import DownloadPopup from "@/components/DownloadPopup.vue";
import ListArray from "@/components/ListArray.vue";
import {EditType} from "@/utils/enums";

export default {
  name: "MypageTeacherClassPreWorksheet",
  components: {
    ListArray,
    DownloadPopup,
    MypageList,
    Popup,
    TakeTestPopup,
  },
  data() {
    return {
      schoolTypes: models.schoolTypeList(true).map((i, index) => {
        return {
          'value': index,
          'name': i,
          'isSelected': false,
        }
      }),
      secondOptions: [],
      tags: models.sheetTagList().map((i, index) => {
        return {
          'value': index,
          'name': i,
          'isSelected': false,
        }
      }),
      isExcludeWrong: true,
      searchKeyword: null,

      selectTabIndex: 0,
      tabs: [
        {title: '내 학습지'},
        {title: '즐겨찾는 학습지'},
        {title: '공유 학습지'},
      ],

      worksheets: [],

      isOpenDeletePopup: false,
      utils,

      takeTestWorksheet: null,
      downloadWorksheet: null,

      paginateData: {
        'size': 10,
        'page': 1,
        'totalPage': 1,
        'maxVisiblePage': 1 + 5 + 5,
      },
      isLoading: true,
    };
  },
  mounted() {
    this.refreshList();
  },
  methods: {
    redirectCopy(id) {
      this.$router.push({ name: utils.isV2Url(this) ? 'TeacherWorksheetEdit' : 'WorksheetEdit', query: {'id': id, 'type': EditType.preWorksheet} })
    },
    redirectEdit(id) {
      this.$router.push({ name: utils.isV2Url(this) ? 'TeacherWorksheetEdit' : 'WorksheetEdit', query: {'id': id} })
    },
    async onDeleteWorksheetTarget(ws) {
      this.worksheets.forEach(i => i.isChecked = i.id == ws.id)
      this.isOpenDeletePopup = true
    },
    async onShareWorksheetTarget(ws) {
      const res = await WorksheetService.patchWorksheet(ws.id, {
        'is_share': !ws.is_share,
      });
      if (res) {
        this.worksheets.find(i => i.id == ws.id).is_share = res.is_share;
        this.$toast.success(res.is_share ? '학습지를 공유하였습니다' : '학습지 공유를 해제하였습니다');
      } else {
        this.$toast.error('학습지를 공유 중 오류가 발생하였습니다');
      }
    },
    async onDeleteWorksheets() {
      if (this.checkedWorksheets.length == 0) {
        this.$toast.warning('삭제할 학습지를 선택해주세요')
      } else {
        await WorksheetService.deleteWorksheet({
          id_list: this.checkedWorksheets.map(i => i.id)
        })
        this.$toast.success('해당 학습지들이 삭제되었습니다')
        await this.changePage(1);
      }
    },
    async onCreateFavoriteWorksheet() {
      const checkedWorksheets = this.worksheets.filter(i => i.isChecked)

      if (checkedWorksheets.length == 0) {
        this.$toast.warning('삭제할 학습지를 선택해주세요')
      } else {
        await WorksheetService.createFavoriteWorksheet({
          id_list: checkedWorksheets.map(i => i.id)
        })
        this.$toast.success('해당 학습지들을 즐겨찾기 등록하였습니다')
        this.selectTabIndex = 1
        await this.changePage(1);
      }
    },
    async onDeleteFavoriteWorksheet() {
      const checkedWorksheets = this.worksheets.filter(i => i.isChecked)

      if (checkedWorksheets.length == 0) {
        this.$toast.warning('삭제할 학습지를 선택해주세요')
      } else {
        await WorksheetService.deleteFavoriteWorksheet({
          id_list: checkedWorksheets.map(i => i.id)
        })
        this.$toast.success('해당 학습지들을 즐겨찾기 등록 해제하였습니다')
        await this.changePage(1);
      }
    },
    onClickCloseBtn() {
      this.worksheets.forEach(i => i.isChecked = false)
    },
    onChangeItem(item) {
      const findItem = this.worksheets.find(i => i.id == item.id)
      if (findItem) {
        findItem.isChecked = !findItem.isChecked
      }
    },
    async onChangeTab(index) {
      this.selectTabIndex = index;
      await this.changePage(1);
    },
    async onChangeSchoolType(value) {
      this.schoolTypes.forEach(i => i.isSelected = i.value == value)

      const selectSchoolType = this.schoolTypes.find(i => i.isSelected)
      if (selectSchoolType) {
        this.secondOptions = []

        switch (selectSchoolType.name) {
          case '초등학교':
            this.secondOptions = Array.apply({}, Array(6)).map((_, index) => {
              const value = index + 1
              return {'value': value, 'name': `${value}학년`, 'isSelected': false}
            })
            break
          case '중학교':
            this.secondOptions = Array.apply({}, Array(3)).map((_, index) => {
              const value = index + 1
              return {'value': value, 'name': `${value}학년`, 'isSelected': false}
            })
            break
          case '고등학교':
            this.secondOptions = models.subjectList().map((i, index) => {
              return {'value': index, 'name': i, 'isSelected': false}
            })
            break
        }
      }

      await this.changePage(1);
    },
    async onChangeSecondOption(value) {
      this.secondOptions.forEach(i => i.isSelected = i.value == value)
      await this.changePage(1);
    },
    async onChangeTag(value) {
      this.tags.forEach(i => i.isSelected = i.value == value)
      await this.changePage(1);
    },
    async changePage(page) {
      this.paginateData.page = page;
      await this.refreshList();
    },
    async refreshList() {
      this.worksheets = [];
      this.isLoading = true;

      let { items, page, page_size, total_pages } = await WorksheetService.getWorksheetList(
        this.paginateData.size,
        this.paginateData.page,
        this.selectTabIndex !== 2,
        this.selectedSchoolType,
        this.selectedSchoolYear,
        this.selectedSubject,
        this.selectedTag,
        this.isExcludeWrong,
        this.searchKeyword,
        this.selectTabIndex == 1,
        this.selectTabIndex == 2,
      );

      this.isLoading = false;
      this.worksheets = items;
      this.paginateData.size = page_size;
      this.paginateData.page = page;
      this.paginateData.totalPage = total_pages;
    },
    onClickDownload(worksheet) {
      this.downloadWorksheet = worksheet;
    },
  },
  computed: {
    selectedTag() {
      return this.tags.find(i => i.name != '전체' && i.isSelected)?.name;
    },
    selectedSubject() {
      const selectedSecondOption = this.selectedSecondOption;
      if (!selectedSecondOption) return null;

      const selectedSchoolType = this.selectedSchoolType;
      if (!selectedSchoolType) return null;
      if (selectedSchoolType != '고등학교') return null;

      return selectedSecondOption;
    },
    selectedSchoolYear() {
      const selectedSecondOption = this.selectedSecondOption;
      if (!selectedSecondOption) return null;

      const selectedSchoolType = this.selectedSchoolType;
      if (!selectedSchoolType) return null;
      if (selectedSchoolType != '초등학교' && selectedSchoolType != '중학교') return null;

      return selectedSecondOption.replace('학년', '');
    },
    selectedSecondOption() {
      return this.secondOptions.find(i => i.name != '전체' && i.isSelected)?.name;
    },
    selectedSchoolType() {
      return this.schoolTypes.find(i => i.name != '전체' && i.isSelected)?.name;
    },
    checkedWorksheets() {
      return this.worksheets.filter(i => i.isChecked)
    },
    listData() {
      let heads = [];
      let trList = [];

      const isShareTab = this.selectTabIndex === 2;

      if (!isShareTab) heads.push({title: '선택', type: 'checkbox', addClass: 'w-75px'});
      heads.push({title: '학년', type: 'string', addClass: 'w-100px'});
      heads.push({title: '태그', type: 'string', addClass: 'w-100px'});
      heads.push({title: '학습지명', type: 'string', addClass: 'flex-1'});
      heads.push({title: '생성일', type: 'string', addClass: 'w-80px'});
      heads.push({title: '출제자', type: 'string', addClass: 'w-80px'});
      heads.push({title: '미리보기', type: 'prev-btn', addClass: 'w-80px'});
      if (!isShareTab) heads.push({title: '출제', type: 'submit-btn', addClass: 'w-110px'});
      heads.push({title: '', type: 'etc-btn', addClass: 'w-50px'});

      this.worksheets.forEach((worksheet) => {
        let trItem = [];
        if (!isShareTab) trItem.push({value: `${worksheet.id}`, isChecked: worksheet.isChecked, onChange: () => this.onChangeItem(worksheet)});
        trItem.push({value: worksheet.grade});
        trItem.push({value: worksheet.tag});
        trItem.push({value: worksheet.name});
        trItem.push({value: worksheet.create_dt});
        trItem.push({value: worksheet.user_name});
        trItem.push({value: '', onClickPrevBtn: () => utils.previewLink(this, 'preview', utils.PdfType.worksheet, worksheet.id)});
        if (!isShareTab) trItem.push({value: '', onClickSubmitBtn: () => this.takeTestWorksheet = worksheet});

        // 케밥 아이콘 버튼
        let customList = [];
        if (!isShareTab) customList.push({title: '수정', onClick: () => this.redirectEdit(worksheet.id)});
        if (!isShareTab || worksheet.is_my) customList.push({title: worksheet.is_share ? '공유해제' : '공유', onClick: () => this.onShareWorksheetTarget(worksheet)});
        customList.push({title: '복제', onClick: () => this.redirectCopy(worksheet.id)});
        customList.push({title: '다운로드', onClick: () => this.onClickDownload(worksheet)});
        if (!isShareTab) customList.push({title: '삭제', onClick: () => this.onDeleteWorksheetTarget(worksheet)});
        trItem.push({value: '', customList: customList});

        if (!isShareTab) trItem.forEach(i => {
          i.onClick = () => this.onChangeItem(worksheet);
        });

        if (!isShareTab || worksheet.is_share) trList.push(trItem);
      });


      return {
        heads: heads,
        trList: trList,
      };
    },
    filterData() {
      let list = [
        {type: 'select',    addClass: 'w-100px',    name: '학년', options: this.schoolTypes, onChange: this.onChangeSchoolType},
      ]

      if (this.secondOptions && this.secondOptions.length > 0) {
        list.push({type: 'select-arrow'})
        list.push({
          type: 'select',
          addClass: 'w-105px',
          name: '선택',
          options: this.secondOptions,
          onChange: this.onChangeSecondOption,
        })
      }

      list = Array.concat(list, [
        {type: 'select',    addClass: 'w-130px',    name: '학습지 태그', options: this.tags, onChange: (value) => this.onChangeTag(value)},
        {type: 'checkbox',  addClass: '',           name: '오답학습지 제외', value: this.isExcludeWrong, onChange: async (value) => {
          this.isExcludeWrong = value;
          await this.changePage(1);
        }},
      ])

      return {
        title: '학습지 필터',
        list: list,
        createBtn: {
          isVisible: this.selectTabIndex !== 2,
          name: '학습지 만들기',
          onCreate: () => {
            this.$router.push({ name: utils.isV2Url(this) ? 'TeacherWorksheetEdit' : 'WorksheetEdit' });
          },
        },
        searchInput: {
          placeholder: '학습지명 검색',
          onChange: async (value) => {
            this.searchKeyword = value;
            await this.changePage(1);
          },
        },
      }
    },
  },
}
</script>
