<template>
  <div class="s-title">
    <p>{{ this.title }}</p>
    <b-button class="s-tooltip"
              v-b-tooltip.hover.html="this.tooltipData"
    >
      <img src="@/assets/images/tooltip.png" alt="">
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'TitleTooltip',
  props: {
    title: String,
    tooltip: String,
  },
  data() {
    return {
      tooltipData: {
        title: this.tooltip,
      },
    };
  },
}
</script>

<style scoped lang="scss">
.s-title {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
  &>p {
    font-weight: 800;
    font-size: 20px;
    color: #000000;
  }
  .s-tooltip {
    background-color: transparent;
    border-color: transparent;
    width: 24px;
    height: 24px;
    padding: 0;
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }
}
</style>
